@import "../../scss/variables";
@import "../../scss/mixin";

section.privacy_policy {
    .content_wrap {
        h1 {
            margin-bottom: 48px;
        }

        h2 {
            margin-top: 24px;
        }

        h3 {
            margin-top: 24px;
        }

        p {
            margin-top: 16px;
        }

        ul {
            margin-top: 16px;

            li {
                @include font('Poppins-Regular', 20px, 26px, 400, $text);

                @media only screen and (max-width:$tab) {
                    @include font('Poppins-Regular', 18px, 24px, 400, $text);
                }
            }

        }

    }
}