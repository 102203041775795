@import "../../scss/variables";
@import "../../scss/mixin";

section.forgot_password {
  .content_wrap{
    .bi_sec {
      min-height: calc(100vh - 92px);
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0px;
  
      @media only screen and (max-width:$tab) {
        grid-template-columns: 1fr;
      }
  
      .img_wraper {
        display: grid;
        place-items: center;
  
        @media only screen and (max-width:$tab) {
          display: none;
        }
  
        img {
          max-width:400px;
          width:100%;
        }
      }
  
      .form_wraper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 48px;
        padding-right: 48px;
        overflow: hidden;
  
        &.pt {
          padding-top: 48px;
        }
  
        @media only screen and (max-width:$tab) {
          padding-left: 32px;
          padding-right: 32px;
        }
  
        @media only screen and (max-width:$mobile) {
          padding-left: 0x;
          padding-right:0px;
        }
  
        p.suggestion {
          color: $text;
          margin-top: 8px;
          text-align: right;
          margin-top: 32px;
  
          a {
            color: $primary;
            transition: all 0.3s ease-in-out;
  
            &:hover {
              color: $secondary;
            }
          }
        }
  
        form {
          width: 350px;
          @media only screen and (max-width:$mobile){
            max-width:100%;
          }
       
          @media only screen and (max-width:mobile) {
            width: 100%;
          }
          button.submit_btn {
            border: 1px solid $primary;
            border-radius: 56px;
            background-color: transparent;
            color: $primary;
            transition: all 0.3s ease-in-out;
            width:100%;
            padding:16px;
            margin-top:24px;
            &:hover {
              background-color: $primary;
              color: $white;
              ;
            }
    
          }
  
          .title {
            margin-bottom: 32px;
  
            h3{
              color: $primary;
            }
          }
  
          
  
          p.forgot {
            display: flex;
            justify-content: flex-end;
            margin-top: 8px;
          }
          .password_wrap{
            width:100%;
            position:relative;
            button{
              position:absolute;
              top:50%;
              right:12px;
              transform:translateY(-50%);
              width:26px;
              padding:0px;
              margin:0px;
              border-style: none;
              background-color: transparent;
              svg{
                font-size:18px;
              }
            }
          }
  
  
          .success {
            margin-top: 16px;
            padding: 8px 16px;
            border-radius: 56px;
            background-color: $secondary;
  
            p {
              color: $white;
              text-align: center;
            }
          }
        }
  
       
     
  
      }
    }
  }
  
}



.field_wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  margin-top: 16px;

  label {
    @include font('Poppins-Regular', 16px, 24px, 400, $secondary);
  }

  input,
  select {
    padding: 12px 16px;
    border-radius: 56px;
    border: 1px solid rgba($primary, 0.6);
    width: 100%;
    @include font('Poppins-Regular', 16px, 24px, 400, $text);
    transition: all 0.3s ease-in-out;

    &::placeholder {
      color: $primary;
    }

    &:focus {
      border: 1px solid $secondary;
      outline: none;
      box-shadow: none;
      &::placeholder {
        color: $secondary;
      }
    }
  }


}