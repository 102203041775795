@import "../../../../scss/variables";

.dropdown {
    button {
        border-style: none;
        background-color: transparent;
        position: relative;
        padding:8px 16px;
        &:after {
            display: none;
        }

        svg {
            color: $primary;
            font-size: 24px;
            transition: all 0.3s ease-in-out;
        }

        &.active {
            &::after {
                content: "";
                position: absolute;
                top: -1px;
                right: -1px;
                height: 8px;
                width: 8px;
                background-color: $primary;
                border-radius: 50%;
            }
        }

        &:hover {
            svg {
                color: $secondary;
            }

            &:after {
                background-color: $secondary;
            }
        }
    }

    .dropdown-menu {
        padding: 0px;
        border-radius: 16px;
        overflow: hidden;   

        &.profile {
            max-width: 350px;
            width:350px;
            @media only screen and (max-width:$mobile){
                max-width: 300px;
                width:300px;
            }          


            .user_info {
                background-repeat: no-repeat;
                background-size: contain;
                background-position: top;
                position: relative;
                overflow: hidden;
                transform-origin:top center;
                border-radius: 0px 0px 24px 24px;
                    min-height:180px;
                    

                img.bg {
                    position: absolute;
                    width: 100%;
                    top: 0px;
                    left: 0px;
                    z-index: -1;
                    min-height: 312px;
                    min-width: 100%;
                }
                .loading{
                    display:grid;
                    place-items: center;
                    p{
                        text-align: center;
                    }
                }

                .user_content {
                    padding: 24px 16px;
                    position: relative;

                    .user_profile {
                        height:72px;
                        width:72px;
                        border-radius: 50%;
                        margin-bottom: 8px;
                        position:relative;

                        img {
                            height: 72px;
                            width: 72px;
                            border-radius: 50%;
                            object-fit: cover;
                        }
                        button.edit{
                            position:absolute;
                            bottom:0%;
                            right:0%;                          
                            border-radius: 50%;
                            height:32px;
                            width:32px;
                            display:grid;
                            background-color: $primary;
                            place-items:center;
                            opacity:0;
                            svg{
                                font-size:14px;
                                color:$white;
                            }
                        }
                        &:hover{
                            button.edit{
                                opacity:1;
                            }
                        }
                    }

                    p {
                        color: $white;
                    }

                    button {
                        margin-top: 16px;
                        padding: 4px 8px;
                        background-color: rgba($white, 0.1);
                        border-radius: 8px;
                        border-style: none;
                        font-size: 12px;
                        letter-spacing: 1px;
                        font-weight: lighter;
                        color: $white;
                        transition: all 0.3s ease-in-out;

                        &:hover {
                            background-color: rgba($white, 0.2);
                        }

                    }
                }

            }

            .btns_wrap_box {
                display: flex;
                flex-direction: column;
                padding: 16px 0px;
                transform-origin:bottom center;
                button.profile_menu {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 16px;
                    padding: 12px 16px;
                    background-color: transparent;
                    transition: all 0.3s ease-in-out;
                    .left {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 16px;
                    }

                    span {
                        svg {
                            color: $text;
                            font-size: 14px;
                        }
                    }

                    &:hover {
                        background-color: rgba($secondary, 0.1);
                    }

                }
            }
        }
    }
}