@import "../../../scss/variables";

.quize_result {
 

    .modal-body {
        padding: 48px;
        gap: 8px;
        position: relative;

       

        @media only screen and (max-width:$tab) {
            padding: 32px;
        }

        @media only screen and (max-width:$mobile) {
            padding: 16px;
        }

        img {
            width: 150px;
        }

        h4 {
            text-align: center;
        }

        p {
            text-align: center;
        }

        .score_bord {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            gap: 16px;
            margin-top: 24px;

            .info_box {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 8px;

                svg {
                    font-size: 40px;

                    @media only screen and (max-width:$tab) {
                        font-size: 24px;
                    }
                }

                &.a {
                    svg {
                        color: $secondary;
                    }
                }

                &.b {
                    svg {
                        color: $primary;
                    }
                }

                &.c {
                    svg {
                        color: $error;
                    }
                }
            }


        }

        .go_chapter_btn {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin: 24px 0px;

            @media only screen and (max-width:$mobile) {
                margin: 16px 0px;
            }
            button.secondary_btn{
                width:156px;
            }
        }
    }
}