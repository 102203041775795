

.modal-body{
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    gap:24px; 
    padding-bottom:32px;
    h3{
        text-align: center;
    }
}