@import "../../../scss/variables";

header.lms {
    background-color: $white;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    position:sticky;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 9;
    box-shadow: 0 1px 5px 1px rgba(0,0,0,0.05), 0 2px 2px rgba(0,0,0,0.05);

    .content_wrap {

        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;

        a.logolink {
            img {
                max-width: 56px;
                max-height: 56px;
                object-fit: contain;
            }
        }

        nav {
            display: flex;
            justify-content: space-around;
            align-items: center;
            gap: 32px;

            @media only screen and (max-width:$menubreak) {
                display: none;
            }

            a {
                transition: all 0.3s ease-in-out;
            }
        }

        .btn_wraper {
            display: flex;
            justify-content: flex-end;
            gap:8px;
          

            button{
                border-style: none;
                background-color: transparent;
                position: relative;
                padding:4px;
               
                &.auth_menu{
                    display:none;
                    @media only screen and (max-width:$menubreak){
                        display:block;
                    }
                }

                svg {
                    font-size: 24px;
                    transition: all 0.3s ease-in-out;
                }

                &.active {
                    &::after {
                        content: "";
                        position: absolute;
                        top: -1px;
                        right: -1px;
                        height: 8px;
                        width: 8px;
                        background-color: $primary;
                        border-radius: 50%;
                    }
                    &.cart{
                       
                        &:after{
                            top:6px;
                            right:0px;
                        }
                    }
                   
                }

                &:hover {
                    svg {
                        color: $secondary;
                    }

                    &:after {
                        background-color: $secondary;
                    }
                }
            }

            button.menu {
                border-style: none;
                background-color: transparent;
                display: none;
                margin-left: 16px;

                svg {
                    font-size: 24px;
                    transition: all 0.3s ease-in-out;
                }

                &:hover {
                    svg {
                        color: $secondary;
                    }
                }

                @media only screen and (max-width:$menubreak) {
                    display: inline-block;
                }
            }

       

        }
    }
}