@import "../../../scss/variables";

section.left_right_sec {
    .content_wrap {
        .bi_grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 48px;

            @media only screen and (max-width:$tab) {
                grid-template-columns: 1fr;
                gap:24px;
            }

            .img_wrap {
                img {
                    width: 100%;
                    border-radius: 16px;
                }
            }

            .text_content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 16px;
                height: 100%;

                p.t_20{
                    text-align: justify;
                    @media only screen and (max-width:$mobile){
                        text-align:left;
                    }
                }
            }
        }
    }
}