@import "../../../scss/variables";


.course_card {
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    cursor: pointer;
    .img_wrap {
        padding:16px 16px 0px;
        min-height:150px;
        
        img {
            border-radius: 4px;
            min-width: 100%;
            max-height: 180px;
            object-fit:cover;
        }
    }

    .info {
        padding: 16px;

        h4 {
            margin-top: 8px;
            color: $text;
            display:-webkit-box;
            -webkit-line-clamp:1;
            -webkit-box-orient: vertical;  
            overflow: hidden;
        }
      

        .trainer {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;
            margin-top: 8px;

            svg {
                color: $text;
                font-size: 18px;
            }
        }

        .prise {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 8px;
            margin-top: 8px;

            .left {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 8px;

                h5 {
                    color: $secondary;
                }

                .pill {
                    padding: 4px 12px;
                    border-radius: 32px;
                    background-color: rgba($primary, 0.2);

                    p {
                        color: $primary;
                        font-size:16px;
                    }
                }
            }

            .btns {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 8px;
            }

            button {
                border-style: none;
                background-color: transparent;

                svg {
                    color: $text;
                    font-size: 22px;
                    transition: all 0.3s ease-in-out;
                }

                &.filled {
                    svg {
                        color: $primary;
                        font-size: 22px;
                    }
                }

                &.cart {
                    svg {
                        font-size: 24px;
                        transition: all 0.3s ease-in-out;
                    }

                }

                &:hover {
                    svg {
                        color: $secondary;
                    }
                }
            }
        }

        .progress_info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 8px;
            margin-top: 16px;

            .progress {
                height: 10px;
                width: calc(100% - 32px);
                background-color: rgba($text, 0.04);
                border-radius: 32px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
               
background-color: rgba($primary, 0.3);
                .filler {
                    height: 100%;
                    background-color: $primary;
                    border-radius: 32px;

                }
            }
        }

    }

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 4px;
    }
}