@import "../../scss/variables";
@import "../../scss/mixin";

button.normal_btn {
    padding: 18px 24px;
    border-style: none;
    background: transparent;
    border-radius: 56px;
    border: 1px solid $white;
    background-color: $white;
    min-width: 160px;
    display:flex;
    justify-content: center;
    align-items:center;
    gap:8px;
    color:$secondary;
    transition: border 0.3s ease-in-out;
    svg{
        font-size:18px;
    }

    &.bordered {
        border: 1px solid $secondary;
        transition: color 0.3s ease-in-out;
        transition: background-color 0.3s ease-in-out;
    }

    &.filled {
        background:$secondary;
        color: $white;
    }

    &:hover {
        border: 1px solid $secondary;

        &.bordered {
            background:$secondary;
            color: $white;
        }
    }

    @media only screen and (max-width:$tab) {
        padding: 14px 16px;
        min-width: 100px;
    }

}