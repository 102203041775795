@import "../../../scss/variables";

.offcanvas-backdrop {
  z-index: 99999;
}

.offcanvas {
  z-index: 99999;
  background-color: $ternery;

  .offcanvas-header {
    button {
      &:focus {
        box-shadow: none;
        outline: none;
      }
    }

    img {
      width: 48px;

    }
  }

  .side_links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    a {
      padding: 4px 8px;
    }

    .offering_dd {
      position: relative;
      transition: all 0.3s ease-in-out;

      .sub_navs {
        background-color: $white;
        position: absolute;
        top: 24px;
        left: 56px;
        transform-origin: top center;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
        padding: 16px;
        border-radius: 8px;
        transform: scaleY(0);
        transition: all 0.3s ease-in-out;

        a {
          padding: 4px 0px;
        }
      }

      &:hover {
        .sub_navs {
          gap: 2px;
          transform: translateX(-50%) scaleY(1);
        }

      }
    }

    .btn_line {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      margin-top: 24px;
    }
  }
}