section.certificate{
    .content_wrap{

        .skeleton{
            display:flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap:8px;
            h4{
                text-align: center;
                max-width:400px;
                margin-top:100px;
            }
            button.secondary_btn{
                max-width:160px;
                display: inline-block;
                margin-top:24px;
            }
        }
            
    }
}