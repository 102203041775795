@import "../../../scss/variables";

section.data_counter {
    .content_wrap {
        .title_wrap {
            max-width: 1100px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;

            h2 {
                text-align: center;
            }
        }

        .counter_grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 24px;

            .counter_card {
                padding: 32px 24px;
                border-radius: 24px;
                background-color: #bde1dd7f;

                .count_progress {
                    margin: 8px 0px;
                    height: 10px;
                    width: 100%;
                    border-radius: 8px;
                    overflow: hidden;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .count_filler {
                        height: 100%;
                        transform-origin: left;
                    }
                }

                .count_progress {
                    background-color: rgba($secondary, 0.3);

                    .count_filler {
                        background-color: $secondary;
                    }
                }

                h1 {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    span {
                        font-size: 84px;
                        font-weight: 400;
                    }
                }

                p {
                    margin-top: 8px;
                    color: $secondary;
                }

                &.a {
                    background-color: rgba(#9BA2FF, 0.1);

                    h1,
                    p {
                        color: #9BA2FF;
                    }

                    .count_progress {
                        background-color: rgba(#9BA2FF, 0.3);

                        .count_filler {
                            background-color: #9BA2FF;
                        }
                    }
                }

                &.b {
                    background-color: rgba(#A14DA0, 0.1);

                    h1,
                    p {
                        color: #A14DA0;
                    }

                    .count_progress {
                        background-color: rgba(#A14DA0, 0.3);

                        .count_filler {
                            background-color: #A14DA0;
                        }
                    }
                }

                &.c {
                    background-color: rgba(#8a4f7d, 0.1);

                    h1,
                    p {
                        color: #8a4f7d;
                    }

                    .count_progress {
                        background-color: rgba(#8a4f7d, 0.3);

                        .count_filler {
                            background-color: #8a4f7d;
                        }
                    }
                }



            }

            @media only screen and (max-width:$laptop) {
                grid-template-columns: 1fr 1fr;

                .counter_card {
                    h1 {
                        span {
                            font-size: 60px;
                        }
                    }
                }
            }

            @media only screen and (max-width:$mobile) {
                grid-template-columns: 1fr;

                .counter_card {
                    h1 {
                        span {
                            font-size: 48px;
                        }
                    }
                }
            }
        }
    }
}