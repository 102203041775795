@import "../../../scss/variables";

section.get_app{
    overflow:hidden;
    .content_wrap{
        .bi_grid{
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap:48px;
            @media only screen and (max-width:$tab){
                grid-template-columns: 1fr;
                gap:32px;
            }
            .img_wrap{
                img{
                    width:100%;
                    border-radius: 16px;
                }
            }
            .text_btn{
                display:flex;
                flex-direction:column;
                align-items: flex-start;
                justify-content: center;
                .app_cta_btns {
                    margin-top:16px;
                    display: flex;
                    gap: 16px;
                    justify-content: flex-end;
                    align-items: flex-start;
                    flex-wrap: wrap;
                    @media only screen and (max-width:850px){
                        gap:8px;
                        justify-content: flex-start;
                    }
    
                    a {
                        margin:0px;
                        padding:12px 16px;
                        border-radius: 56px;
                        background-color:$primary;
                        border-style: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 8px;
                        &:hover{
                            background-color:$secondary;
                        }
    
                        svg {
                            color: $white;
                            font-size: 24px;
                        }
    
                        .text {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: center;
                            p{
                                text-align: left;
                                font-size:12px;
                                line-height:12px;
                                color:$white;
                                margin:0px;
                            }
                            h6{
                                margin:0px;
                                font-size:16px;
                                line-height:16px;
                                color:$white;
                            }
                        }
    
    
                    }
                }
            }
            p{
                margin-top:8px;
            }
        }
    }
}