@import "../../scss/variables";

.text_pill{
    display:inline-block;
    justify-self: flex-start;
    padding:12px;
    border-radius:8px;
    max-width:300px;
    width:100%;
    p{
        color:$white;
        font-size:14px;
        line-height:18px;
        margin:0px;
        word-wrap: break-word;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform:capitalize;

        
    }
    &.secondary{
        background-color:$secondary;
    }
    &.primary{
        background-color:$primary;
    }
    &.yellow{
        background-color: $darkYellow;
    }
    &.text{
        background-color: $text;
    }
    &.green{
        background-color: $green;
    }
}

