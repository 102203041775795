@import "../../scss/variables";

.payment_sucess {
    .content_wrap {
        height:50vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;

        h1 {
            text-align: center;

            p {
                text-align: center;
            }
        }

        button {
            margin-top: 16px;
        }
    }
}