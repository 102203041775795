@import "../../../../scss/variables";

.delet_item_pop {
    background-color: rgba($dark, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    padding-bottom: 60px;


    .popup_card {
        border-radius: 16px;
        padding: 16px;
        background-color: $white;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        transform-origin: bottom center;

        .line {
            margin-top: 16px;
            height: 8px;
            width: 48px;
            background-color: $secondary;
            border-radius: 16px;
        }

        h4 {
            text-align: center;
        }

        .info_card {
            display: grid;
            grid-template-columns: 0.46fr 1fr;
            gap: 16px;

            @media only screen and (max-width:$mobile) {
                grid-template-columns: 1fr;
            }

            .img_wrap {
                img {
                    width: 100%;
                    border-radius: 4px;
                }
            }

            .content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                gap: 8px;

                .name_line {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 8px;

                    svg {
                        font-size: 16px;
                        color: $text;
                    }
                }

                .price_line {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 8px;

                    h5 {
                        color: $secondary;
                        margin: 0px;
                    }

                    .pill {
                        padding: 4px 12px;
                        background-color: rgba($primary, 0.2);
                        border-radius: 24px;

                        p {
                            color: $primary;
                        }
                    }
                }
            }

        }

        .btns_wrap {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;

            @media only screen and (max-width:$mobile) {
                flex-direction: column;
            }
        }
    }
}