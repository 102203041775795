@import "../../../scss/variables";

section.about_text_slider {
    padding-left: 0px;
    padding-right: 0px;
    overflow: hidden;
    .marque_line{
        transform:rotate(-4deg);
        .text_box {
            padding: 32px;
            border-radius: 56px;
            background-color:#b0e3ef ;
            @media only screen and (max-width:$tab){
                padding:24px;    
            }
            @media only screen and (max-width:$mobile){
                padding:16px;    
            }
            h3{
                color:$secondary;
            }
        }
    }    
}