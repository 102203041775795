@import "../../scss/variables";

section.quize {
    .content_wrap {
        .time_line {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            margin-top: 24px;
            position: relative;

            svg{
                color:$error;
                font-size:24px;
            }

            button.back {
                border-style: none;
                background-color: $primary;
                border-radius: 4px;
                padding: 8px;
                color: $white;
                position: absolute;
                left: 0px;
                top: 50%;
                transform: translateY(-50%);

                svg {
                    font-size: 18px;
                    color: $white;
                }
            }


        }

        .btn_wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            margin-top: 24px;
            padding-bottom: 24px;
            margin-bottom: 24px;
            border-bottom: 1px solid rgba($text, 0.3);


            button {
                border-radius: 50%;
                height: 32px;
                width: 32px;
                display: grid;
                place-items: center;
                border: 1px solid $white;
                background-color: $white;

                svg {
                    color: $secondary ;
                }

                p {
                    font-size: 14px;
                    line-height: 14px;
                }

                &.passed {
                    border: 1px solid $secondary;
                }

                &.current {
                    background-color: $secondary;
                    color: $white;
                }
            }
        }

        .que_num {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 16px;

            .pill {
                padding: 4px 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 4px;
                border-radius: 16px;
                background-color: $primary;

                svg {
                    color: $white;
                    font-size: 18px;
                }

                p {
                    color: $white;
                }
            }
        }

        .question_wrap {
            margin-top: 16px;
        
h4{
    display:flex;
    justify-content: flex-start;
    align-items: center;
    gap:8px;
}

            p {
                color: $dark;
            }

            .options {
                display: flex;
                flex-direction: column;
                gap: 16px;
                margin-top: 16px;

                button.option {
                    background-color: transparent;
                    width: 300px;
                    padding: 14px 16px;
                    border-radius: 32px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    border: 1px solid rgba($text, 0.4);
                    transition: all 0.3s ease-in-out;
                    color: $dark;
                    line-height: 18px;

                    &.success {
                        border: 1px solid $primary;
                        background-color: $primary;

                        p {
                            color: $white;
                        }
                    }

                    &.error {
                        border: 1px solid $error;
                        background-color: $error;

                        p {
                            color: $white;
                        }
                    }


                    &:hover {
                        border: 1px solid $primary;
                        background: $primary;
                        color: $white;
                    }

                }
            }
        }

     

        .navigation_btns {
            margin-top: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 16px;

            button {
                height: 48px;
                width: 48px;
                border-radius: 50%;
                border: 1px solid $primary;
                display: grid;
                place-items: center;
                background-color: $white;
                transition: all 0.3s ease-in-out;

                &:disabled {
                    opacity: 0.6;
                    pointer-events: none;
                }

                svg {
                    font-size: 24px;
                    color: $primary;
                    transition: all 0.3s ease-in-out;
                }

                &:hover {
                    background-color: $primary;

                    svg {
                        color: $white;
                    }
                }

            }
        }
    }
}

