@import "../../../scss/variables";


.cart_sidebar {
    .offcanvas-header {
        border-bottom: 1px solid rgba($text, 0.3);

        button.back {
            border-style: none;
            background-color: transparent;

            svg {
                font-size: 22px;
                color: $dark;
            }
        }
    }

    .offcanvas-body {
        position: relative;

        .success_screen {
            padding-top: 100px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
            gap: 8px;

            h5 {
                text-align: center;
            }

            p {
                text-align: center;
                font-size: 14px;
                line-height: 18px;
                max-width: 250px;

            }

        }

        .cart_items {
            display: flex;
            flex-direction: column;
            gap: 8px;
            border-bottom: 1px solid rgba($text, 0.3);
            padding-bottom: 16px;

            .item_card {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 16px;
                border-radius: 8px;
                box-shadow: 2px 2px 6px rgb(239, 239, 239);
                position: relative;

                button.delete {
                    position: absolute;
                    top: 8px;
                    right: 8px;
                    border-style: none;
                    background-color: transparent;

                    svg {
                        color: $dark;
                        font-size: 24px;
                    }
                }

                .img_wrap {
                    width: 120px;

                    img {
                        width: 100%;
                        max-height: 100px;
                        border-radius: 4px;
                        ;
                        object-fit: contain;
                    }
                }

                .text {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 4px;
                    padding: 8px 8px 8px 0px;

                    h6 {
                        margin: 0px;
                    }

                    .name_line {
                        display: flex;
                        justify-content: flex-start;
                        gap: 8px;
                        align-items: center;
                        margin: 4px 0px;

                        svg {
                            font-size: 14px;
                            line-height: 14px;
                            color: $text;
                        }

                        p {
                            font-size: 14px;
                            line-height: 14px;
                        }
                    }

                    .price_line {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 8px;

                        h6 {
                            font-size: 16px;
                            line-height: 16px;
                            color: $secondary;
                            margin: 0px;
                        }

                        .pill {
                            padding: 4px 8px;
                            background-color: rgba($primary, 0.2);
                            border-radius: 24px;

                            p {
                                color: $primary;
                                font-size: 14px;
                                line-height: 14px;
                            }
                        }
                    }
                }
            }
        }

        .promocode_wrap {
            margin-top: 16px;

            .field_wraper {
                position: relative;

                input {
                    width: 100%;
                    padding: 8px 16px;
                    border-radius: 4px;
                    border: 1px solid rgba($text, 0.2);
                    background-color: transparent;
                }

                button {
                    position: absolute;
                    top: 50%;
                    right: 8px;
                    transform: translateY(-50%);
                    background-color: $primary;
                    color: $white;
                    transition: all 0.3s ease-in-out;
                    border-radius: 4px;
                    border-style: none;
                    padding: 8px;


                    .spinner-border {
                        height: 20px;
                        width: 20px;
                        border: 3px solid $white !important;
                        border-right: 3px solid transparent !important;
                    }

                    svg {
                        font-size: 20px;
                        color: white;
                        transition: all 0.3s ease-in-out;
                    }
                }
            }
        }

        .coupon_card {
            padding: 8px;
            border: 1px solid rgba($text, 0.2);
            border-radius: 8px;
            margin-top:8px;
            display:flex;
            justify-content: space-between;
            align-items: center;
            button.secondary_btn{
                width:100px;
            }
            .info{
                p{
                    font-size:14px;
                }
            }
           
        }

        .total_count {
            margin-top: 16px;

            .total_line {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 16px;

                &.total {
                    border-top: 1px solid rgba($text, 0.3);
                    padding-top: 8px;
                    margin-top: 2px;

                    p,
                    h6 {
                        color: $secondary;
                        font-size: 18px;
                        line-height: 18px;
                    }
                }
            }
        }

        button.bottom {
            position: fixed;
            bottom: 8px;
            right: 8px;
            max-width: 384px;
            width: calc(100% - 16px)
        }

        .empty_cart {
            padding: 48px 0px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            p {
                max-width: 200px;
                margin-bottom: 16px;

            }
        }
    }
}