@import "../../../../scss/variables";

section.course_module{
    padding-bottom:48px;
    .content_wrap{
        .modules_grid{
            margin-top:24px;
            display:grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap:32px;
            @media only screen and (max-width:$laptop){
                grid-template-columns: 1fr 1fr;
                gap:24px;
            }
            @media only screen and (max-width:$tab){
                grid-template-columns: 1fr;
                gap:16px;
            }

            .module_card{
                cursor:pointer;
                border-radius: 8px;
                padding:16px;
                box-shadow: 2px 2px 6px rgb(239, 239, 239);
                h5{
                    font-weight:400;
                    color:$text;
                    display:flex;
                    justify-content: space-between;
                    align-items: center;
                    svg{
                        font-size:22px;
                        color:$primary;
                    }
                }

                .img_wrap{
                    margin:8px 0px;
                    position:relative;
                    img{
                        width:100%;
                        max-height:200px;
                        object-fit: contain;
                    }
                    .lock{
                        position:absolute;
                        top:50%;
                        left:50%;
                        transform:translate(-50%, -50%);
                        height:48px;
                        width:48px;
                        border-radius: 50%;;
                        background-color: rgba($dark, 0.5);
                        display:grid;
                        place-items: center;
                        svg{
                            color:$white;
                            font-size:18px;
                        }

                    }
                }
                .line{
                    display:flex;
                    justify-content: space-between;
                    align-items: center;
                    gap:8px;
                    svg{
                        font-size:22px;
                        color:$primary;
                    }
                }
            }
        }
    }
}