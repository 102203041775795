@import "../../../scss/variables";
@import "../../../scss/mixin";

section.home_landing {
    overflow: hidden;
    padding-bottom: 100px;

    video {
        position: absolute;
        top: 0px;
        left: 0px;
        object-fit: cover;
        min-height:648px;
        height: 100vh;
        width: 100%;
        z-index: 0;

    }

   

    .content_wrap {
        position: relative;
        z-index: 2;
        min-height: calc(100vh - 100px);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .text_wrap_desktop {
            height: 100px;
            overflow: hidden;
            transform-origin: left;
            transform:translateY(16px);

            @media only screen and (max-width:$tab) {
                display: none;
            }
        }

        .text_wrap_tab {
            height: 60px;
            overflow: hidden;
            transform-origin: left;
            display: none;

            @media only screen and (max-width:$tab) {
                display: block;
            }

            @media only screen and (max-width:$mobile) {
                display: none;
            }
        }

        .text_wrap_mobile {
            height: 40px;
            overflow: hidden;
            transform-origin: left;
            display: none;

            @media only screen and (max-width:$mobile) {
                display: block;
            }
        }

        h1 {
            @include font('Poppins-Medium', 80px, 80px, 400, $white);
            transform-origin: left;

            @media only screen and (max-width:$tab) {
                @include font('Poppins-Medium', 56px, 56px, 400, $white);
            }

            @media only screen and (max-width:$mobile) {
                @include font('Poppins-Medium', 36px, 36px, 400, $white);
            }
            &.brand{
                color:$primary;
            }
        }

        p {
            color: $white;
            max-width:500px;
            width: 100%;
            margin-top: 8px;
            @include font('Poppins-Medium', 18px, 22px, 400, $white);
        }

        .btn_wrap {
            margin-top: 24px;
            display:flex;
            justify-content: space-between;
            align-items: center;
            @media only screen and (max-width:850px){
                flex-direction: column;
                align-items: flex-start;
                gap:8px;
            }
            .left {
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                gap: 16px;
                @media only screen and (max-width:850px){
                    gap:8px;
                }
            }

            .right {
                display: flex;
                gap: 16px;
                justify-content: flex-end;
                flex-wrap: wrap;
                @media only screen and (max-width:850px){
                    gap:8px;
                    justify-content: flex-start;
                }

                button {
                    margin:0px;
                    padding:12px 16px;
                    border-radius: 56px;
                    background-color: rgba($white, 0.15);
                    border-style: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 8px;
                    transition:all 0.3s ease-in-out;
                    &:hover{
                        background-color: rgba($white, 0.2);
                    }

                    svg {
                        color: $white;
                        font-size: 24px;
                    }

                    .text {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: center;
                        p{
                            text-align: left;
                            font-size:12px;
                            line-height:12px;
                            color:$white;
                            margin:0px;
                        }
                        h6{
                            margin:0px;
                            font-size:16px;
                            line-height:16px;
                            color:$white;
                        }
                    }


                }
            }
        }
    }

}