@import "../../../scss/variables";

section.course_landing {
    .content_wrap {
        .landing_card {
            background-color: $ternery;
            padding-left: 16px;
            padding-right: 16px;
            border-radius: 32px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            padding-bottom:200px;
            @media only screen and (max-width:$tab){
                padding-bottom:180px;
            }
            @media only screen and (max-width:$mobile){
                padding-bottom:100px;
            }

            h1 {
                text-align: center;
            }

            p {
                text-align: center;
                margin: 16px;
            }

        }
        .img_line{
            display:flex;
            justify-content: center;
            img{
                max-width:60%;
                transform:translateY(-100px);
                max-height:400px;
                object-fit: contain;
                @media only screen and (max-width:$tab){
                   max-width:80%;
                }
                @media only screen and (max-width:$mobile){
                    transform:translateY(-56px);
                }
            }
        }
       
    }
}