@import "../../../scss/variables";

section.team {
    background-color:#BDE1DD;
    overflow: hidden;

    .content_wrap {
        h2 {
            text-align: center;
        }
        h4{
            margin-top:16px;
        }
       
        .team_grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 48px;

            @media only screen and (max-width:$tab) {
                grid-template-columns: 1fr;
            }

            .team_wrap {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 24px;

                img {

                    border-radius: 50%;
                    max-width:300px;
                }

                h3 {
                    text-align: center;
                }
            }
        }

        .image_slider{
            button.slick-next{
                display:none!important;
            }
            button.slick-prev{
                display:none!important;
            }
            .slick-slider{
                .slick-list{
                    .slick-slide{
                        padding:8px;
                        img{
                            width:100%;
                            border-radius:16px;
                            height:300px;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }
}