@import "../../../scss/variables";

section.course_acc {
    .content_wrap {
        .bi_sec {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 0px;

            .text {
                padding-right: 60px;
            }

            .accordion_side {
                .accordion {
                    .accordion-item {
                        margin-bottom: 24px;
                        border-radius: 32px;
                        background-color: $bgLight;
                        border-style: none;

                        h2.accordion-header {
                            button.accordion-button {
                                background-color: $bgLight;
                                border-radius: 32px;
                                padding: 0px;
                                

                                &:after {
                                    display: none;
                                }

                                &:focus {
                                    box-shadow: none;
                                    outline: none;
                                }

                                .btn_content {
                                    padding: 32px;
                                    @media only screen and (max-width:$mobile){
                                        padding:24px;
                                    }

                                    h4 {
                                        padding-right: 48px;
                                        margin-top: 16px;
                                    }

                                    .arrow_line {
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;

                                        svg {
                                            transform: rotate(180deg);
                                            color: $primary;
                                            font-size: 24px;
                                            transform-origin: center;
                                            transition: all 0.3s ease-in-out;
                                            @media only screen and (max-width:$tab){
                                                font-size:18px;
                                            }
                                        }
                                    }
                                }

                                &.collapsed {
                                    .arrow_line {

                                        svg {
                                            transform: rotate(0deg);
                                        }
                                    }
                                }


                            }
                        }

                        .accordion-body {
                            padding: 0px 32px 32px 32px;
                            @media only screen and (max-width:$mobile){
                                padding: 0px 24px 24px 24px;
                            }

                            p {
                                color: $darkText;
                            }

                            .list_item {
                                margin-top: 8px;
                                display: flex;
                                justify-content: flex-start;
                                align-items: flex-start;
                                gap: 8px;

                                &.mt {
                                    margin-top: 16px;
                                }

                                svg {
                                    font-size: 26px;
                                    color: $secondary;
                                }

                                p {
                                    width: calc(100% - 40px);
                                    color: $text;
                                }
                            }
                        }
                    }
                }
            }

            @media only screen and (max-width:$laptop){
                grid-template-columns: 1fr;
                gap:48px;
            }
        }
    }
}