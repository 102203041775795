@import "../../../scss/variables";


section.lms_subjects {
    margin-top: 32px;

    .content_wrap {
        .title_wrap{
            display:flex;
            justify-content: space-between;
            align-items: center;
            button.view_more_btn{
                background-color: transparent;
                border-style: none;
                transition:all 0.3s ease-in-out;
                &:hover{
                    color:$secondary;
                }
            }
        }
        .subject_grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 16px;
            margin-top:24px;
            @media only screen and (max-width:$desktop){
                grid-template-columns: 1fr 1fr;
            }
            @media only screen and (max-width:650px){
                grid-template-columns: 1fr;
            }
            @media only screen and (max-width:$mobile){
             gap:8px;
            }

            .subject {
                padding:32px;
                border-radius: 8px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: $tomatoLight;
                cursor:pointer;

                img{
                    width:48px;
                }
                @media only screen and (max-width:$laptop){
                    padding:24px;
                }
                @media only screen and (max-width:$mobile){
                    padding:16px;
                }

            
            
            
            }
        }
    }
}