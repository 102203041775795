@import "../../../../../scss/variables";

.log_out {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba($dark, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .logout_modal {
        background-color: $white;
        padding: 24px 16px 32px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
transform-origin:bottom center;
        .line {
            background-color: $secondary;
            height: 8px;
            width:48px;
            border-radius: 32px;
        }

        h4 {
            margin-top: 32px;
            text-align: center;            
        }

        p {
            text-align: center;         
            margin-top:8px;
            margin-bottom:24px;
        }
        button{
            margin-top:8px;
        }
    }
}