@import "../../../scss/variables";


section.error_sec {
    .content_wrap {
        .error_card{
            min-height:50vh;
            display:flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap:8px;
            h3{
                margin-top:16px;
                text-align: center;
            }
            img{
                max-width:350px;
                width:100%;
            }
            p{
                margin-bottom:24px;
                text-align: center;
            }
        }
    }
}