@import "../../../scss/variables";

section.needs {
    .content_wrap {
        .title{
            h2{
                text-align: center;
                
            }
        }
        .four_grid {
            margin-top: 100px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 24px;

            @media only screen and (max-width:$desktop){
                grid-template-columns: 1fr 1fr;
            }

            @media only screen and (max-width:$tab){
                grid-template-columns: 1fr;
            }

            .card_info {
                padding: 24px;
                border-radius: 24px;
                transition: all 0.3s ease-in-out;

                .icon_line {
                    margin-bottom: 24px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

               
                    .icon_box {
                        height: 48px;
                        width: 48px;
                        border-radius: 8px;
                        background-color: $white;
                        display: grid;
                        place-items: center;

                        img{
                            max-width: 48px;
                            max-height: 48px;
                            object-fit: contain;
                          }
                    }
                }

                .text {
                    height: 250px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }

                &.a {
                    background-color: rgba($yellow, 0.1);

                    &:hover {
                        background-color: rgba($yellow, 0.3);
                    }

                    h4,
                    p,
                    svg {
                        color: $yellow;
                    }
                }

                &.b {
                    background-color: rgba($green, 0.1);

                    &:hover {
                        background-color: rgba($green, 0.3);
                    }

                    h4,
                    p,
                    svg {
                        color: $green;
                    }
                }

                &.c {
                    background-color: rgba($yellow, 0.1);

                    &:hover {
                        background-color: rgba($yellow, 0.3);
                    }

                    h4,
                    p,
                    svg {
                        color: $yellow;
                    }
                }

                &.d {
                    background-color: rgba($secondary, 0.1);

                    &:hover {
                        background-color: rgba($secondary, 0.3);
                    }

                    h4,
                    p,
                    svg {
                        color: $secondary;
                    }
                }
                &:hover{
                    .icon_line{
                        svg{
                            opacity:1;
                        }
                    }
                }
            }
        }
    }
}