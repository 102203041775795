@import "../../scss/variables";
@import "../../scss/mixin";

header {
    background-color: $white;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 9999;

    .content_wrap {

        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;

        @media only screen and (max-width:$mobile) {
            gap: 8px;
        }

        a.logolink {
            img {
                max-width: 56px;
                max-height: 56px;
                object-fit: contain;

                @media only screen and (max-width:$mobile) {
                    max-width: 48px;
                    max-height: 48px;
                }
            }
        }

        nav {
            display: flex;
            justify-content: space-around;
            align-items: center;
            gap: 32px;

            @media only screen and (max-width:$menubreak) {
                display: none;
            }

            a {
                transition: all 0.3s ease-in-out;
                @include font('Poppins-Medium', 18px, 22px, 500, $secondary);
                transition:all 0.3s ease-in-out;
                &:hover, &.active{
                    color:$primary;
                }
            }

            .offering_dd {
                position: relative;
                transition: all 0.3s ease-in-out;

                .sub_navs {
                    background-color: $white;
                    position: absolute;
                    top: 24px;
                    left: 50%;
                    transform-origin: top center;
                    display: flex;
                    flex-direction: column;
                    overflow: hidden;
                    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
                    padding: 16px;
                    border-radius: 8px;

                    transform: translateX(-50%) scaleY(0);
                    transition: all 0.3s ease-in-out;

                    a {
                        padding: 4px 0px;
                    }
                }

                &:hover {
                    .sub_navs {
                        gap: 2px;
                        transform: translateX(-50%) scaleY(1);
                    }

                }
            }
        }

        .btn_wraper {
            display: flex;
            justify-content: flex-end;
            gap: 8px;

            button.menu {
                border-style: none;
                background-color: transparent;
                display: none;
                margin-left: 16px;

                svg {
                    font-size: 24px;
                    transition: all 0.3s ease-in-out;
                    color:$secondary;
                }


                @media only screen and (max-width:$menubreak) {
                    display: inline-block;
                }

                @media only screen and (max-width:$mobile) {
                    margin-left: 0px;
                }



            }
        }
    }
}