@import "../../../../../scss/variables";

.help_center{
    padding:16px;
    transform-origin: top center;
    h6{
        margin-top:16px;
    }
    p{        
        line-height:20px;
        &.t_14{
margin-bottom:24px;
        }
    }
    textarea{
        padding:16px;
        width:100%;
        border-radius: 8px;
        min-height:100px;
        margin:8px 0px 8px;
        border-style: none;
        background-color: rgba($text, 0.08);
        &:focus{
            outline: none;
        }
    }
}