@import "../../../scss/variables";

section.trusted_by {
    background-color: $white;

    .content_wrap {
        .bi_grid {
            border-radius: 32px;
            display: grid;
            grid-template-columns: 1fr 0.8fr;
            gap: 0px;
            background-color:#BDE1DD;
            padding: 64px;


            .left {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                gap: 32px;
            }

            .trusted_grid {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 16px;
                background-color: $white;
                padding: 32px;
                border-radius: 32px;

                .logo_wrap {
                    background-color:$ternery;
                    border-radius: 16px;
                    display: grid;
                    place-items: center;
                    padding:16px;

                    img {
                        max-width:100%;
                        max-height:100%;
                        object-fit: contain;
                    }
                }
            }

            @media only screen and (max-width:$laptop) {
                grid-template-columns: 1fr;
                gap: 48px;
            }

            @media only screen and (max-width:$tab) {
                padding: 40px;

                .trusted_grid {
                    grid-template-columns: 1fr 1fr;
                    gap: 12px;
                    @media only screen and (max-width:$mobile) {
                        padding:24px;
                    }
                    .logo_wrap {
                        padding: 24px;
                        @media only screen and (max-width:$mobile) {
                            padding:12px;
                        }
                    }
                }
            }
            @media only screen and (max-width:$tab) {
                padding:32px;
            }
           
        }
    }
}