@import "../../../../../scss/variables";

.change_password {
    padding: 16px;
    transform-origin: top center;


    .success{
        padding:16px;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transform-origin: top center;
        
        h4{
            text-align: center;
            margin-top:16px;
        }
        p{
            text-align: center;
        }
        
    }


    form {
        margin-top:16px;

        .password_wrap {
            position: relative !important;
            margin-top: 16px;

            button.eye {
                border-style: none;
                background-color: transparent;
                position: absolute !important;
                top: 50%;
                right: 0px;
                transform: translateY(-50%);

                svg {
                    font-size: 18px !important;
                }
            }

            input {
                border-style: none;
                width: 100%;
                border-radius: 8px;
                padding: 8px 16px;
                background-color: rgba($text, 0.08);
            }           
        }
        p.error {
            color: $error;
            font-size: 14px;
            padding-left: 16px;
        }
        button.secondary_btn{
            margin-top:16px;
        }
    }

}