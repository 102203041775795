@import "../../../../scss/variables";

section.course_overview {
padding-bottom:48px;
    .content_wrap {
        h3{
            margin-bottom:16px;
        }
        .profile_wrap {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 24px;
            gap: 8px;

            .img_wrap {
                height: 60px;
                width: 60px;
                border-radius: 50%;
                overflow: hidden;

                img {
                    height: 60px;
                    width: 60px;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }

        }

        .count_info {
            margin-top:32px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            column-gap: 32px;
            row-gap: 8px;
        }

        .bi_grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 24px;
            margin-top:48px;

            @media only screen and (max-width:$tab) {
                grid-template-columns: 1fr;
            }

            .flex_box {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                column-gap: 16px;
                row-gap: 8px;
                margin-top: 8px;

                .square {
                    padding: 2px 8px;
                    border-radius: 4px;
                    display: inline-block;
                    border: 1px solid rgba($text, 0.4);

                    p {
                        display: inline-block;
                        text-wrap: nowrap;
                    }
                }
            }
        }

        .bottom_btn {
            padding-top:32px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 32px;
            margin-top: 48px;  
            border-top:1px solid rgba($text, 0.5);          


            
        }
    }
}

