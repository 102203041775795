@import "../../../scss/variables";

section.impact_sec {
    .content_wrap {
        .title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-width: 600px;
            margin-left: auto;
            margin-right: auto;
            gap: 8px;
            position: relative;
            z-index: 2;

            h2,
            p {
                text-align: center;
            }

            button {
                margin-top: 32px;
            }
        }

        .three_grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 24px;

            .card_wrap {
                padding: 32px;
                border-radius: 32px;
                display:flex;
                flex-direction: column;
                align-items: flex-start;

                h4{
                    margin-top:auto;                   
                    bottom:0px;
                }

                .pill_line {
                    display: flex;
                    justify-content:flex-start;
                    align-items: center;

                    svg {
                        font-size: 32px;
                        opacity: 0;
                        transition: all 0.3s ease-in-out;
                    }
                }

                h3 {
                    margin: 56px 0px 24px;
                }

                &.a {
                    background-color:rgba(#49BDB6, 0.2);

                    h3,h4,
                    svg {
                        color:#49BDB6;
                    }
                }

                &.b {
                    background-color:rgba(#206583, 0.2);

                    h3,h4,
                    svg {
                        color:#206583;
                    }
                }

                &.c {
                    background-color:rgba(#9BA2FF, 0.2);

                    h3,h4,
                    svg {
                        color: #9BA2FF;
                    }
                }

                &.d {
                    background-color:rgba(#8B2635, 0.2);

                    h3,h4,
                    svg {
                        color: #8B2635;
                    }
                }
                &.e {
                    background-color:rgba(#A14DA0, 0.2);

                    h3,h4,
                    svg {
                        color: #A14DA0;
                    }
                }
                &.f {
                    background-color:rgba(#8A4F7D, 0.2);

                    h3,h4,
                    svg {
                        color: #8A4F7D;
                    }
                }

                &:hover {
                    svg {
                        opacity: 1;
                    }
                }

            }

            .differe {
                grid-column: span 3;
                padding: 32px;
                border-radius: 32px;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 60px;
                background-color: $ternery;
                align-self: flex-start;

                p {
                    color: $secondary;

                    &:nth-child(2) {
                        margin-top: 16px;
                    }
                }

                h4 {
                    color: $secondary;
                }
            }

            @media only screen and (max-width:$laptop) {
                grid-template-columns: 1fr 1fr;
            }

            @media only screen and (max-width:$tab) {
                grid-template-columns: 1fr;

                .differe {
                    grid-column: span 1;
                    gap: 32px;
                }
            }

            @media only screen and (max-width:$mobile) {
                .card_wrap {
                    padding: 24px;
                }

                .differe {
                    padding: 24px;
                    grid-column: span 1;
                    grid-template-columns: 1fr;
                    gap: 16px;

                    .left {
                        p {
                            margin: 0px;
                        }
                    }
                }
            }
        }
    }
}