@import "../../../scss/variables";

section.consult_key_areas {
    .content_wrap {
        &.tab {
            @media only screen and (max-width:$tab) {
                display: none;
            }
        }

        &.small {
            display: none;

            @media only screen and (max-width:$tab) {
                display: block;
            }
        }

        .bi_grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 48px;
            margin-top: 60px;

            .left_text {
                position: relative;

                .line {
                    position: absolute;
                    top: 88px;
                    left: 0px;
                    height: 100%;
                    border-left: 1px dashed $primary;
                }

                .sticky_line {
                    position: sticky;
                    top: 30vh;
                    left: -1px;
                    height: 300px;
                    border-left: 2px solid $primary;
                }

                .content {
                    padding-left: 48px;
                    margin-bottom: 60vh;

                    h3 {
                        margin-bottom: 16px;
                    }

                    ul {
                        margin-top: 16px;

                        li {
                            margin-bottom: 8px;
                        }
                    }
                }
            }

            .right_boxes {
                align-self: flex-start;
                position: sticky;
                top: 25vh;
                height: 50vh;
                min-height: 450px;
                border-radius: 24px;
                overflow: hidden;

                .box {
                    height: 100%;
                    width: 100%;
                    transform-origin: bottom center;
                    transition: all 0.6s ease-in-out;
                    padding: 56px;
                    display: grid;
                    place-items: center;
                    overflow: hidden !important;
                    position: relative;

                    img {
                        max-width: 100%;
                        height: 250px;
                        object-fit: contain;
                    }

                    &.a {
                        background-color: #8B2635;
                        z-index: 0;
                    }

                    &.b {
                        background-color: #A14DA0;
                        position: absolute;
                        left: 0px;
                        top: 102%;
                        z-index: 1;

                        &.active {
                            top: 0px;
                        }
                    }

                    &.c {
                        background-color: #9BA2FF;
                        position: absolute;
                        left: 0px;
                        top: 102%;
                        z-index: 2;

                        &.active {
                            top: 0px;
                        }
                    }

                    &.d {
                        background-color: $yellow;
                        position: absolute;
                        left: 0px;
                        top: 102%;
                        z-index: 3;

                        &.active {
                            top: 0px;
                        }
                    }

                    &.e {
                        background-color: #206583;
                        position: absolute;
                        left: 0px;
                        top: 102%;
                        z-index: 4;

                        &.active {
                            top: 0px;
                        }
                    }



                }
            }
        }

        .small_card {
            margin-top:48px;
            .box_card {
                border-radius: 24px;
                padding: 48px;
                width: 100%;
                height: 350px;
                display: grid;
                place-items: center;

                img {
                    max-height: 250px;
                    max-width:100%;
                    object-fit: contain;
                }

                &.a {
                    background-color: #8B2635;
                }

                &.b {
                    background-color: #A14DA0;
                }

                &.c {
                    background-color: #9BA2FF;
                }

                &.d {
                    background-color: $yellow;
                }
            }

            h3{
                margin-top:16px;
            }
        }
    }
}