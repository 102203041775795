@import "../../scss/variables";



section.offering {
    .content_wrap {
        h1 {
            text-align: center;
        }

        .offer_grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 16px;

            @media only screen and (max-width:$desktop) {
                grid-template-columns: 1fr 1fr;
            }

            @media only screen and (max-width:$tab) {
                grid-template-columns: 1fr;
            }

            .offer_card {
                padding: 24px;
                border-radius: 32px;
                background-color: #bde1dd31;
                cursor:pointer;
                .icon_box {
                    height: 48px;
                    width: 48px;
                    border-radius: 8px;
                    display: grid;
                    place-items: center;
                    margin-bottom: 8px;
                    background-color: $primary;

                    svg {
                        color: $white;
                        font-size: 24px;
                    }
                    img{
                        width:28px;
                    }
                }

                p {
                    color: $primary;
                    margin-top: 100px;
                }

                h3 {
                    color: $primary;
                }

                &.a {
                    background: rgba(#8B2635, 0.1);

                    .icon_box {
                        background-color: #8B2635;
                    }

                    p,
                    h3 {
                        color: #8B2635;
                    }
                }

                &.b {
                    background: rgba(#8a4f7d, 0.1);

                    .icon_box {
                        background-color: #8a4f7d;
                    }

                    p,
                    h3 {
                        color: #8a4f7d;
                    }
                }

                &.c {
                    background: rgba(#A14DA0, 0.1);

                    .icon_box {
                        background-color: #A14DA0;
                    }

                    p,
                    h3 {
                        color: #A14DA0;
                    }
                }

                &.d {
                    background: rgba(#9BA2FF, 0.1);

                    .icon_box {
                        background-color: #9BA2FF;
                    }

                    p,
                    h3 {
                        color: #9BA2FF;
                    }
                }

            }
        }
    }
}