@import "../../../scss/variables";

section.quote {
    .content_wrap {
        .quote_card {
            border-radius: 32px;
            background-color: $ternery;
            padding: 60px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @media only screen and (max-width:$mobile){
               padding:48px 16px; 
            }

            h3 {
                text-align: center;
                max-width: 750px;
                width: 100%;
                margin: 32px 0px;
            }

            p {
                text-align: center;
                &.t_18 {
                    color: $primary;
                    margin-bottom: 4px;
                }
            }

        }
    }
}