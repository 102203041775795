@import "../../../scss/variables";

.offering_slider {
    width: 100%;
    overflow: hidden;
    position:relative;

    .slick-slider {
        pointer-events: none;

        .slick-list {
            .slick-track {
                .slick-slide {
                    .offering_slide {
                        height:calc(100vh - 88px);
                        min-height: 500px;
                        width: 100%;
                        position: relative;

                        .content_wrap {
                            position: relative;
                            z-index: 2;
                            height:100%;
                            display:flex;
                            flex-direction:column;
                            justify-content: center;
                            h1{
                                color:$primary;
                            }
                            p{
                                color:$white;
                                max-width:600px;
                                width:100%;
                                margin-top:8px;
                            }
                        }

                        img {                 
                            min-width:100%;
                            min-height:100%; 
                            width:100%;
                            height:100%;          
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            object-fit:cover;
                            z-index: 0;
                        }
                        .dark_overlay{
                            width:100%;
                            height:100%;
                            position:absolute;
                            top:0px;
                            left:0px;
                            z-index:1;
                            background-color:rgba($dark, 0.4);
                        }
                    }
                }
            }
        }

        ul.slick-dots {
            display: none;
        }
    }

    .dot_line {
        margin-top: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        position:absolute;
        bottom:32px;
        left:0px;
        width:100%;

        .pill_wraper {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            background-color: $secondary;
            height: 48px;
            width: 150px;
            border-radius: 32px;

            button {
                height: 12px;
                width: 12px;
                border-radius: 16px;
                background-color: $primary;
                border-style: none;
                transition: all 0.8s ease-in-out;

                &.active {
                    width: 48px;
                    background-color: $primary;
                }
            }
        }

        button.redirect {
            border-style: none;
            background-color: transparent;
            height:48px;
            width: 160px;
            border-radius:32px;
            display: grid;
            place-items: center;
            background-color: $secondary;
            display: flex;
            justify-content: center;
            align-items: center;
            gap:8px;
            color:$white;

            svg {
                font-size:24px;
                color: $white;
            }
        }

    }

}