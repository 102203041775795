@import "../../scss/variables";





section.chapter_detail {
    padding-bottom:48px;
    .content_wrap {
        .chapter_view {



            .chapter_title {
                margin-top: 24px;
                margin-bottom: 16px;

                h3 {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 8px;
                }
            }

            .h_line {
                margin: 16px 0px;
                border-bottom: 1px solid rgba($text, 0.2);
            }

            .section_content {
                margin-top:48px;
                h5 {
                    margin-bottom: 8px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 8px;

                    svg {
                        font-size: 20px;
                        transform: rotate(90deg);
                    }
                }
            }

            .description_sec {
                margin-top:48px;
                h5 {
                    margin-bottom: 16px;
                }
            }


            .assignment_sec {
                margin-bottom: 16px;
                margin-top:48px;

                h3 {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 8px;
                }

                button.secondary_btn {
                    display: inline-block;
                    margin-top: 16px;
                    width: 200px;
                }
            }

            .quize_section{
                margin-top:48px;
                h5{
                    margin-bottom:16px;
                }
                button.secondary_btn{
                    width:130px;
                }
            }


            .video_sec{
                margin-top:48px;
            }
        
        }
        .mark_btn_wrap{
            margin-top:32px;
            display:flex;
            justify-content: flex-end;
            align-items: center;
            button.secondary_btn{
                width:248px;
            }
        }
    }
}