@import "../../../../../scss/variables";

.faq {
    padding: 16px;
    transform-origin: top center;
    max-height: 500px;
    overflow: auto;
    transform-origin: top center;

    .input_wraper {
        position: relative;
        margin-top: 16px;

        svg {
            position: absolute;
            top: 50%;
            left: 8px;
            transform: translateY(-50%);
            font-size: 18px;
            color: $text;
        }

        input {
            padding: 8px 16px;
            background-color: rgba($text, 0.08);
            border-style: none;
            border-radius: 8px;
            padding-left: 32px;
            width:100%;
        }
    }

    h6 {
        margin-top: 8px;
    }

    .faq_list {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .faq_card {
            padding: 16px;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
            cursor:pointer;
            p {
                color: $dark;
                font-size: 14px;
                line-height: 16px;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;

                &.light {
                    color: $text;
                    margin-top: 8px;
                }
            }
        }
    }
}