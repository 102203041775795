@import "../../../scss/variables";



.bookmark_remove_modal {
    padding-top:48px;
    .modal-body {
        .line {
            margin-top: 16px;
            height: 8px;
            width: 48px;
            background-color: $secondary;
            border-radius: 16px;
        }
        h4{
            text-align: center;
        }
        .info_card {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 16px;
            @media only screen and (max-width:$mobile){
                grid-template-columns: 1fr;
            }

            .img_wrap {
                img {
                    width: 100%;
                }
            }

            .content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                gap:8px;

                .name_line {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 8px;
                    svg {
                        font-size: 16px;
                        color: $text;
                    }
                }

                .price_line {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap:8px;

                    h5 {
                        color: $secondary;
                        margin:0px;
                    }

                    .pill {
                        padding:4px 12px;
                        background-color: rgba($primary, 0.2);
                        border-radius: 24px;

                        p {
                            color: $primary;
                        }
                    }
                }
            }

        }
        .btns_wrap{
            width:100%;
                display:flex;
                justify-content: center;
                align-items: center;
                gap:8px;
                @media only screen and (max-width:$mobile){
                   flex-direction: column;
                }
        }
    }
}