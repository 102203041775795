
.web_layout{
    display:flex;
    flex-direction: column;
    width:100%;
    min-height:100vh;
    
    .filler{
        height:88px;
    }
}