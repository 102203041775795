@import "../../../../scss/variables";

.course_list_card {
    display: flex;
    flex-direction: column;
    padding-bottom:16px;
    position:relative;
    cursor:pointer;
    .pill_box{
        position:absolute;
        top:0px;
        left:0px;
        z-index:2;
        background-color: $white;
        border-radius: 0px 0px 16px 0px;
        padding:0px 12px 12px 0px;
        &::after{
            content: "";
            position: absolute;
            top: 0px;
            height: 50px;
            right: -25px;
            width: 25px;
            border-top-left-radius: 25px;
            box-shadow: 0 -25px 0 0 #fafafa;
        }
        &::before{
            content: "";
            position: absolute;
            top: 52px;
            height:25px;
            left:0px;
            width:50px;
            border-top-left-radius: 25px;
            box-shadow: -16px 0px 0 0 #fafafa; 
        }

    }

    .img_card {
        overflow: hidden;
        display:grid;
        place-items: center;
        img {
            object-fit:cover;  
            max-height:200px;
            max-width: 100%;
            border-radius: 24px;    
            width:100%;

        }
    }

    p.text{
        margin: 16px 0px 8px;
    }
}