@import "../../../scss/variables";


section.classes_banner {
    .content_wrap {
        .banner_wrap {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 48px;
            padding: 32px;
            background-color: $secondary;
            border-radius: 32px;

            @media only screen and (max-width:$tab) {
                grid-template-columns: 1fr;
                gap: 32px;
            }

            .left {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                h2 {
                    color: $white;
                }

                p {
                    color: $white;
                }

                button.normal_btn {
                    border-style: none;
                    color: $secondary;
                    margin-top: 24px;

                    &:hover {
                        border-style: none;
                    }
                }
            }

            .sketch {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                @media only screen and (max-width:$tab) {
                    justify-content: center;
                }

                img {
                    height:202px;
                    max-width:100%;
                }
            }

        }
    }
}