@import "../../../../scss/variables";


.offcanvas-backdrop {
  z-index: 99999;
}

.offcanvas {
  z-index: 99999;
  background-color: $ternery;

  .offcanvas-header {
    button {
      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
    img{
      width:48px;
      
    }
  }

  .side_links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    .btn_line {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      margin-top: 24px;
    }
  }
}