@import "../../../scss/variables";

section.latest_blogs {
    overflow: hidden;
    .content_wrap {
        .bi_grid {
            display: grid;
            grid-template-columns: 2fr 1fr;
            gap: 24px;
            margin-top: 32px;
            @media only screen and (max-width:$laptop){
                grid-template-columns: 1fr;
            }
            .inner_grid {
                background-color: $ternery;
                padding: 32px;
                border-radius: 32px;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 32px;
                @media only screen and (max-width:$desktop){
                    grid-template-columns: 1fr;
                }

                .text_wrap {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 24px;
                   .pill_wraper{
                    display:flex;
                    justify-content: flex-start;
                   }
                    h3{
                        display: -webkit-box;
                        -webkit-line-clamp:4;
                        -webkit-box-orient: vertical;  
                        overflow: hidden;
                    }
                }

                .img_wrap {
                    border-radius: 24px;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: 50% 50%;
                    }
                }

                .img_wrap {
                    img {
                        width: 100%;
                    }
                }

            }

            .popular_latest {
                max-height:500px;
                overflow:auto;
                .blog_card {
                    padding: 16px 0px;
                    border-bottom: 2px solid $ternery;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 16px;
.pill_wraper{
    display:flex;
    justify-content: flex-start;
}
                    p {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        svg {
                            transition: all 0.3s ease-in-out;
                            opacity: 0;
                            font-size: 20px;
                            color: $primary;
                        }
                    }

                    &:hover {
                        p {
                            svg {
                                opacity: 1;
                            }
                        }
                    }

                }
            }
        }
    }
}