@import "../../scss/variables";


section.cta {
    .content_wrap {
        .content_box {
            border-radius: 32px;
            background-color: $primary;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 32px;
            padding-right: 24px;
            padding-left: 24px;

            h2 {
                color: $white;
                text-align: center;
                max-width: 800px;
                width: 100%;
            }
        }
    }
}