@import "../../../scss/variables";

section.view_blog_landing {
    .content_wrap {

        .bi_part {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 56px;
            @media only screen and (max-width:$tab){
                grid-template-columns: 1fr;
                gap:48px;
            
            }

            .left{
            
            h2 {
                margin-top: 16px;
            }

            p.t_18 {
                margin: 32px 0px;
            }

            p.date {
                color: $secondary;
            }
        }

        .img_wraper{
            img{
                width:100%;
                border-radius:32px;
            }
        }

        }
    }
}