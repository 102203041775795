@import "../../../../../scss/variables";

.notification_setting {
    padding: 16px;
    transform-origin: top center;

    .notify_setting_wraper {
        margin-top: 16px;

        .check_line {
            padding-left: 8px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 12px;
            margin-top: 8px;
            margin-bottom: 16px;

            input[type="checkbox"] {
                position: relative;
                cursor: pointer;

                &:after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    height: 18px;
                    width: 18px;
                    border: 1px solid $secondary;
                    background-color: $white;
                    border-radius: 4px;
                    transition: all 0.3s ease-in-out;
                    z-index: 1;
                }

                &:before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) rotate(-45deg);
                    border-left: 1px solid $white;
                    border-bottom: 1px solid $white;
                    z-index: 2;
                    height: 5px;
                    width: 10px;
                    transition: all 0.3s ease-in-out;
                }

                &:checked {
                    &:after {
                        background-color: $secondary;
                    }
                }
            }

            p {
                font-size: 14px;
                line-height: 18px;
            }
        }
    }
}