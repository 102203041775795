@import "../../scss/variables";

footer {
    background-color: #BDE1DD;
    padding-top: 80px;
    padding-bottom: 80px;
    overflow: hidden;

    @media only screen and (max-width:$tab) {
        padding-bottom: 60px;
        padding-top: 60px;
    }

    section.footer {
        .content_wrap {
            .link_grid {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 2fr;
                gap: 24px;

                .app_cta {
                    display: flex;
                    gap: 16px;
                    justify-content: flex-end;
                    align-items: flex-start;
                    flex-wrap: wrap;

                    @media only screen and (max-width:850px) {
                        gap: 8px;
                        justify-content: flex-start;
                    }

                    button {
                        margin: 0px;
                        padding: 12px 16px;
                        border-radius: 56px;
                        background-color: $secondary;
                        border-style: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 8px;
                        transition: all 0.3s ease-in-out;

                        &:hover {
                            background-color: $primary;
                        }

                        svg {
                            color: $white;
                            font-size: 24px;
                        }

                        .text {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: center;

                            p {
                                text-align: left;
                                font-size: 12px;
                                line-height: 12px;
                                color: $white;
                                margin: 0px;
                            }

                            h6 {
                                margin: 0px;
                                font-size: 16px;
                                line-height: 16px;
                                color: $white;
                            }
                        }


                    }
                }

                .link_col {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                    align-items: flex-start;

                    p {
                        color: $secondary;
                        font-weight: 600;
                    }

                    a {
                        color: $text;
                        transition: all 0.3s ease-in-out;

                        &:hover,
                        &.active {
                            color: $primary;
                        }
                    }
                }

                @media only screen and (max-width:$desktop) {
                    grid-template-columns: 1fr 1fr 1fr;
                }

                @media only screen and (max-width:$tab) {
                    grid-template-columns: 1fr 1fr;
                }

                @media only screen and (max-width:$mobile) {
                    grid-template-columns: 1fr;
                }
            }

            .bottom_line {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 32px;
                margin-top: 60px;


                .center {
                    a {
                        color: $text;
                        transition: all 0.3s ease-in-out;
                        &:hover,
                        &.active {
                            color: $primary;
                        }
                    }
                }

                .social {
                    display: flex;
                    justify-content: flex-end;
                    gap: 32px;

                    a {
                        svg {
                            font-size: 26px;
                            color: $secondary;
                            transition: all 0.3s ease-in-out;
                        }

                        &:hover {
                            svg {
                                color: $primary;
                            }
                        }
                    }

                }

                @media only screen and (max-width:$tab) {
                    display: flex;
                    flex-direction: column-reverse;
                    gap: 24px;

                    p {
                        text-align: center;
                    }

                    .social {
                        display: flex;
                        justify-content: center;
                        gap: 16px;
                    }
                }

            }
        }
    }
}