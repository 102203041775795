@import "../../scss/variables";


section.bookmarks {
    padding-bottom: 48px;
    .content_wrap {
        .filter_pils {
            margin-top: 24px;
            display: flex;
            justify-content: flex-start;
            gap: 8px;
            flex-wrap: wrap;

            .pill {
                border: 1px solid $primary;
                padding: 8px 16px;
                border-radius: 32px;
                background-color: $white;
                transition:all 0.3s ease-in-out;
                cursor:pointer;
                p {
                    color: $dark;
                    font-size:14px;
                    line-height: 16px;
                    transition:all 0.3s ease-in-out;
                }

                &.active, &:hover {
                    background-color: $primary;

                    p {
                        color: $white;
                    }
                }
            }
        }
        .course_grid{
            margin-top:32px;
            display:grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap:32px;
            @media only screen and (max-width:$laptop){
                grid-template-columns: 1fr 1fr;
                gap:24px;
            }
            @media only screen and (max-width:$tab){
                grid-template-columns: 1fr;
                gap:16px;
            }
        }
    }
}