@import "../../scss/variables";
@import "../../scss/mixin";

section.email_sub {
    margin-bottom: 80px;
    background-color: $white;
    margin-top: auto;

    .content_wrap {
        .bi_grid {
           display:flex;
           justify-content: space-between;
           align-items: center;
           gap:48px;

            .text {
                display: flex;
                flex-direction: column;
                gap: 16px;

                p {
                    max-width: 800px;
                    width: 100%;
                }
            }

            .email_box {

                .input_line {
                    gap: 4px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    button{
                        &.primary_btn{
                            
                            min-width:150px;
                        }
                    }
                }

                input {
                    padding: 18px 20px;
                    border-radius: 56px;
                    border: 1px solid $primary;
                    height: 62px;
                    font-size: 16px;
                    @include font('Poppins-Regular', 16px, 20px, 400, $text);
                }
                p.error{
                    color:$error;
                    margin-top:8px;
                }
            }

            @media only screen and (max-width:$desktop) {
                flex-direction: column;
                align-items: flex-start;
                gap: 32px;

                .email_box {
                    justify-content: flex-start;
                }
            }

            @media only screen and (max-width:$tab) {
                gap: 24px;

                .email_box {
                    gap: 8px;

                    input {
                        height: 56px;
                        padding: 14px 16px;
                    }
                }
            }

            @media only screen and (max-width:$mobile) {
                gap: 16px;

                .input_line {
                    flex-direction: column;
                    align-items: flex-start;
                    align-items: flex-start;
                }
            }
        }
    }
}