@import "../../scss/variables";

button.primary_btn {
    position: relative;
    padding: 6px 30px 6px 72px;
    border-radius: 56px;
    background:$secondary;
    color: $white;
    display: flex;
    flex-direction: row;
    border-style: none;
    justify-content: flex-end;
    align-items: center;
    gap: 26px;
    height: 62px;
    transition: padding 0.3s ease-in-out;

    span {
        position: absolute;
        top: 50%;
        left: 6px;
        transform: translateY(-50%);
        background-color: $white;
        display: flex;
        justify-content: center;
        color: $white;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        border: 1px solid $primary;
        transition: all 0.3s ease-in-out;

        svg {
            color: $secondary;
            font-size: 32px;
            text-align: center;
            margin-top: auto;
            margin-bottom: auto;
            transition: all 0.3s ease-in-out;

            @media only screen and (max-width:$tab) {
                font-size: 24px;
            }
        }

    }

    @media only screen and (max-width:$tab) {
        padding: 6px 20px 6px 64px;
        height: 56px;

        span {
            height: 44px;
            width: 44px;
        }
    }

    &:hover {

        padding: 6px 72px 6px 30px;
        justify-content: flex-start;

        @media only screen and (max-width:$tab) {
            padding: 6px 64px 6px 20px;
        }

        span {
            left: calc(100% - 56px);
            border: 1px solid $white;
            color:$secondary;

            @media only screen and (max-width:$tab) {
                right: calc(100% - 50px);
            }

            svg {
                color: $secondary;

            }
        }

    }
}