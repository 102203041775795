@import "../../../scss/variables";

section.related_blogs{
    .content_wrap{
            .title{
                display:flex;
                flex-direction: column;
                align-items:center;
                justify-content: center;
                h2{
                    text-align: center;
                }
                p{
                    text-align: center;
                    margin-top:8px;
                }
            }
            .blog_grid{
                display:grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap:24px;
                @media only screen and (max-width:$desktop){
                    grid-template-columns: 1fr 1fr;
                }
                @media only screen and (max-width:$tab){
                    grid-template-columns: 1fr;
                }
            }

    }
}