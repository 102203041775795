@import "../../../scss/variables";

section.recommended_sec {
    .content_wrap {
        .course_slider {
            margin-top: 32px;
        }

        .swiper-wrapper{
            
            .swiper-slide{                
                padding:16px 4px;
            }
        }

        .view_more{
            display:flex;
            justify-content:center;
            align-items: center;
            margin-top:16px;
            button.secondary_btn{
                width:150px;

            }
        }
    }
}