@import "../../../scss/variables";

section.coaching_grid {
    .content_wrap {
        .career_grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 32px;

            .white_card {
                h2 {
                    margin-bottom: 16px;
                }
            }

            .info_card {
                padding: 16px;
                padding: 24px;
                border-radius: 16px;
                background-color: $ternery;

                .num_box {
                    height: 48px;
                    width: 48px;
                    border-radius: 8px;
                    display: grid;
                    place-items: center;

                    p {
                        color: $white;
                    }

                    &.a {
                        background-color: $secondary;
                    }

                    &.b {
                        background-color: $yellow;
                    }

                    &.c {
                        background-color: $darkYellow;
                    }

                    &.d {
                        background-color: $green;
                    }

                }

                h4 {
                    margin: 60px 0px 8px;
                }
            }

            .text_card {
                position: relative;

                img.arrow {
                    position: absolute;
                    bottom: 0px;
                    left: 0%;
                    transform: translate(40%, 50%) rotate(-15deg);

                }
            }

            @media only screen and (max-width:$laptop) {
                grid-template-columns: 1fr 1fr;

                .white_card {
                    grid-column: 1 / -1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding-bottom: 80px;

                    h2 {
                        margin-bottom: 8px;
                        text-align: center;
                    }

                    p {
                        text-align: center;
                        max-width: 500px;
                        width: 100%;
                    }
                }

                .text_card {
                    display: none;
                }

                .emptyb {
                    display: none;
                }
            }

            @media only screen and (max-width:$tab){
                grid-template-columns: 1fr;
                .emptya {
                    display: none;
                }
            }
        }


    }
}