@import "../../../../../scss/variables";

.edit_profile {
    padding: 16px;
    transform-origin: top center;
    form {
        margin-top: 16px;

      

        .input_wrap {
            margin-bottom: 16px;

            input {
                border-style: none;
                width: 100%;
                border-radius: 8px;
                padding: 8px 16px;
                background-color: rgba($text, 0.08);
            }

            p.error {
                color: $error;
                font-size: 14px;
                padding-left: 16px;
            }
        }
    }

}