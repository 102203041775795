@import "../../../scss/variables";

section.coaching_landing {
    .content_wrap {
        .bi_grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 48px;

            @media only screen and (max-width:$tab) {
                grid-template-columns: 1fr;
                gap: 32px;
            }

            .left_content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 8px;

                p {
                    max-width: 500px;
                    width: 100%;
                    margin-top: 16px;
                }

                .h_line {
                    margin: 32px 0px 24px;
                    height: 1px;
                    max-width: 500px;
                    width: 100%;
                    border-bottom: 1px solid rgba($text, 0.5);
                    transform-origin: left center;
                }


            }

            .img_wrap {
                img {
                    max-width: 100%;
                    border-radius: 16px;
                }
            }

        }
    }
}