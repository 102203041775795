@import "./mixin";
@import "./variables";


@font-face {
    font-family: 'Poppins-Regular';
    src: url('../assets/fonts/Poppins-Regular.woff') format('woff');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url('../assets/fonts/Poppins-Medium.woff') format('woff');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('../assets/fonts/Poppins-SemiBold.woff') format('woff');
    font-style: normal;
    font-display: swap;
}


button {
    @include font('Poppins-Medium', 16px, 16px, 500, $primary);
}

a {
    margin: 0px;
    @include font('Poppins-Medium', 18px, 22px, 500, $secondary);
    text-decoration: none;



    &:hover, &.active {
        color: $primary;
    }
}

p {
    margin: 0px;
    @include font('Poppins-Regular', 20px, 26px, 400, $text);    
}

h1 {
    margin: 0px;
    @include font('Poppins-SemiBold', 48px, 54px, 500, $secondary);
    &.big{
        @include font('Poppins-SemiBold', 64px, 72px, 600, $secondary);
    }
}

h2 {
    margin: 0px;
    @include font('Poppins-Medium', 48px, 54px, 500, $secondary);
    &.mid{
        max-width:700px;
        text-align: center;
        margin-left:auto;
        margin-right:auto;
    }
}

h3{
    margin:0px;
    @include font('Poppins-Medium', 32px, 36px, 500, $secondary);
}

h4{
    margin:0px;
    @include font('Poppins-Medium', 24px, 28px, 500, $secondary);
}

h5{
    margin:0px;
    @include font('Poppins-Medium', 18px, 22px, 500, $dark);
}


@media only screen and (max-width:$tab){
    p{
        @include font('Poppins-Regular', 18px, 24px, 400, $text);      
    }
    h1 {
        @include font('Poppins-SemiBold', 24px, 28px, 600, $secondary);
        &.big{
            @include font('Poppins-SemiBold', 32px, 38px, 600, $secondary);
        }
    }
    h2 {     
        @include font('Poppins-SemiBold', 32px, 36px, 500, $secondary);
    }
}

@media only screen and (max-width:$mobile){
    p{
        @include font('Poppins-Regular', 16px, 22px, 400, $text);      
    }
    h3{     
        @include font('Poppins-Medium', 24px, 28px, 500, $secondary);
    }
    h4{
    @include font('Poppins-Medium', 18px, 24px, 500, $secondary);
    }
}