@import "../../../scss/variables";

section.offering_second{
    .content_wrap{
        .bi_sec{
            display:grid;
            grid-template-columns: 1fr 1fr;
            gap:48px;
            @media only screen and (max-width:$tab){
                grid-template-columns: 1fr;
                gap:32px;
            }
            .img_wrap{
                img{
                    width:100%;
                    border-radius: 16px;
                }

            }
            .text_wrape{
                display:flex;
                flex-direction: column;
                justify-content: center;
                gap:8px;
            }
        }
    }
}