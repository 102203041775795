@import "../../../scss/variables";
@import "../../../scss/mixin";


section.contact_form {
    .content_wrap {
        .bi_grid {
            display: grid;
            grid-template-columns: 1fr;
            gap: 48px;

            @media only screen and (max-width:$tab) {
                grid-template-columns: 1fr;
                gap: 32px;
            }

            .img_wrap{
                display: grid;
                place-items: center;          
                img {
                  max-width: 500px;
                  width: 100%;
                }
              }
          

            .form_wrap {
                display: flex;
                flex-direction: column;
                align-items: center;

                @media only screen and (max-width:$tab) {
                    order: -1;
                }

                h2 {
                    margin-bottom: 32px;
                    text-align: center;
                }

                form {
                    max-width: 400px;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 4px;

                    input, textarea{
                        padding: 12px 16px;
                        width: 100%;
                        margin-top: 8px;
                        border-radius: 8px;
                        border: 1px solid $primary;
                        border-radius:8px;
                        @include font('Poppins-Regular', 16px, 20px, 400, $text);
                        transition:all 0.3s ease-in-out;
                        &:focus {
                            outline: none;
                            border:1px solid $secondary;
                        }

                        &::placeholder {
                            @include font('Poppins-Regular', 16px, 20px, 400, $text);
                        }
                    }

                    textarea{
                        height:150px;
                    }
                
                    button.secondary_btn {
                        margin-top: 16px;
                        padding:16px!important;
                        margin-left: auto;
                        margin-right: auto;
                        border-radius: 56px;
                    }
                }

            }
        }


    }
}