@import "../../../scss/variables";

section.our_vision {
    .content_wrap {
        position: relative;
        @media only screen and (max-width:$laptop){
           padding-top:100px;
        }

        img.bg {
            position: absolute;
            top:100px;
            left: 50%;
            transform: translateX(-50%);
            max-width: 100%;
            max-height: 80vh;
            object-fit: contain;
            z-index: 0;
            @media only screen and (max-width:$laptop){
                top:-50px;
            }
            @media only screen and (max-width:$laptop){
                top:20px;
            }
        }

        .title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
            gap: 8px;
            position: relative;
            z-index: 2;

            h2,
            p {
                text-align: center;
            }
        }

        .vision_grid {
            position: relative;
            z-index: 2;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 0px;
            
            padding:100px 56px;

            .vision_card {

                padding: 48px;
                &.bb{
                    border-left:1px solid $border;
                }
                &.cc{
                    border-top:1px solid $border;
                }
                &.dd{
                    border-left:1px solid $border;
                    border-top:1px solid $border;
                }
                h4{
                    color:$darkText;
                }

                .num_box {
                    height: 48px;
                    width: 48px;
                    border-radius: 8px;
                    display: grid;
                    place-items: center;
                    margin-bottom:24px;

                    p {
                        color: $white;

                    }                   

                    &.a {
                        background-color: $secondary;
                    }

                    &.b {
                        background-color: $primary;
                    }

                    &.c {
                        background-color:#EEABAB;
                    }

                    &.d {
                        background-color:#F2D8AE;
                    }

                }
            }
            @media only screen and (max-width:$laptop){
                grid-template-columns: 1fr;
                padding:60px 0px 0px;
                .vision_card{
                    border-style:none;
                    &.aa{
                        border-style:none;
                    }
                    &.bb{
                        border-style:none;
                    }
                    &.cc{
                        border-style:none;
                    }
                    &.dd{
                        border-style:none;
                    }
                }
            }
            @media only screen and (max-width:$tab){
                .vision_card{
                    padding:24px 0px;
                }
            }
        }
    }
}