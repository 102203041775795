@import "../../../../../scss/variables";


.update_profile_photo{
    transform-origin: top center;
    padding:16px;
        .profile_wrap {
        cursor:pointer;    
        outline:1px dashed $primary;
        width:116px;
        height:116px;
        border-radius:50%;
        display:grid;
        place-items:center;
        margin-bottom:24px;
        margin-top:24px;
        margin-left:auto;
        margin-right:auto;
    
        img {
            width:100px;
            height:100px;
            border-radius:50%; 
            object-fit: cover;   
        }
    }
}
