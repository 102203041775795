@import "../../../../scss/variables";

.tab_wraper{
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap:8px;
    border:1px solid $ternery;
    padding:4px;
    border-radius: 56px;
    margin-bottom:8px;
    border:1px solid $primary;
    button{
        padding:18px 40px;
        border-radius: 56px;
        background-color: transparent;
        border-style:none;
        color:$text;
        font-weight:400;
        &.active{
            background-color: $primary;
            color:$white;
        }
    }
}
