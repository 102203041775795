@import "../../../../../scss/variables";

.screen_title{
    position: relative;
    padding: 8px 0px;

    button.back {
        position: absolute !important;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 8px;
        padding:8px;
        border:1px solid rgba($text, 0.2)!important;

        svg {
            color: $text;
            font-size: 20px !important;
        }

    }

    h6{
        text-align: center;
        color: $dark;
        margin: 0px;
        font-size:18px;
        line-height:20px;
    }
}