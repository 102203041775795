@import "../../../scss/variables";
@import "../../../scss/mixin";

.verify_otp_modal {
    .modal-body {
        padding: 32px 16px;
        position: relative;

        button.close {
            position: absolute;
            top: 16px;
            right: 16px;
            padding: 4px;
            border-style: none;
            background: transparent;

            svg {
                font-size: 24px;
            }
        }

        .field_wrap {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 6px;
            margin-top: 16px;

            label {
                @include font('Poppins-Regular', 16px, 24px, 400, $secondary);
            }

            input,
            select {
                padding: 12px 16px;
                border-radius: 56px;
                border: 1px solid rgba($primary, 0.6);
                width: 100%;
                @include font('Poppins-Regular', 16px, 24px, 400, $text);
                transition: all 0.3s ease-in-out;

                &::placeholder {
                    color: $primary;
                }

                &:focus {
                    border: 1px solid $secondary;
                    outline: none;
                    box-shadow: none;

                    &::placeholder {
                        color: $secondary;
                    }
                }
            }

        }

        form {
            .title{
                p, h3{
                    text-align: center;
                }
            }
            button.otpsubmit {
                margin-top: 24px;
                width: 100%;

                &:hover {
                    background: $primary !important;
                }
            }
        }
    }
}