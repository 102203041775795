@import "../../../../../scss/variables";

.history_wraper {
    padding: 16px;
    transform-origin: top center;
    max-height:70vh;
    overflow: auto;

    .history_list {
        margin-top: 16px;

        .history_card {
            display: flex;
            justify-content: space-between;
            gap: 16px;
            margin-bottom: 8px;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
            padding:8px;
            border-radius: 4px;;
            &:last-child{
                margin-bottom:0px;
            }
            .img_wrap {
                width: 100px;

                img {
                    width: 100%;
                    border-radius: 4px;
                    ;
                }
            }

            .text_wrap {
                width: calc(100% - 116px);
                padding:0px;
                gap:4px;
                h6{
                    margin-bottom:0px;
                }
                p {
                    font-size: 14px;
                    line-height: 18px;
                }

                .name_wrap {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 8px;

                    svg {
                        font-size: 14px !important;
                        color: $text;
                    }

                }
            }
        }
    }
}