@import "../../scss/variables";

section.our_team{
    .content_wrap{
        .bi_sec{
            display:grid;
            grid-template-columns: 0.5fr 1fr;
            gap:48px;
            @media only screen and (max-width:$tab){
                grid-template-columns:1fr; 
                gap:0px;  
            }
        }
        
        .img_wrap{
            display:flex;
            justify-content: center;
            margin-bottom:48px;
            align-self:flex-start;
            position:sticky;
            top:100px;
            left:0px;
            @media only screen and (max-width:$tab){
              position:relative;
              top:0px;
              left:0px;  
            }
         
            img{
                max-width:400px;
                width:100%;
                border-radius: 16px;
            }
        }
        h4{
            margin-bottom:16px;
        }
        p{
            margin-bottom:16px;
        text-align: justify;
        }
    }
}