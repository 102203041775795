// Color

// $primary: #101e57;
// $secondary: #6f71ee;

$primary: #49BDB6;
$secondary: #206583;
$pink:#eeabab;
$lightWood:#f2d8ae;
 
 
$error:#ED6869;




$ternery:#ebedf0;
$bgLight:#F9FAFB;

$green: #417762;
$greenLight: #f0fff4;

$yellow: #f4b03d;
$yellowLight: #fffbed;

$darkYellow: #f08733;
$darkYelowLight: #fef8f1;

$text:#6B7280;
$darkText: #303943;
$border: #b5b5b5;

$white: #ffffff;
$dark: #000000;





// lms
$tomatoLight:rgba(#EEABAB,0.3);
$termericLight:rgba(#F2D8AE,0.3);



// Break Points
$desktop: 1279px;
$laptop: 992px;
$menubreak: 950px;
$tab: 769px;
$mobile: 479px;