@import "../../../../scss/variables";

.dropdown {
    button {
        border-style: none;
        background-color: transparent;
        position: relative;

        &:after {
            display: none;
        }

        svg {
            color: $primary;
            font-size: 24px;
            transition: all 0.3s ease-in-out;
        }

        &.active {
            &::before {
                content: "";
                position: absolute;
                top:8px;
                right:8px;
                height: 8px;
                width: 8px;
                background-color: $primary;
                border-radius: 50%;
            }
        }

        &:hover {
            svg {
                color: $secondary;
            }

            &:after {
                background-color: $secondary;
            }
        }
    }

    .dropdown-menu {
        padding: 0px;
        border-radius: 16px;
        overflow: hidden;

        &.notification {
            padding: 16px;
            max-height: 500px;
            overflow: auto;

            .notification_card {

                border-bottom:1px solid rgba($text, 0.2);
                width: 300px;
                padding: 16px 0px;
                margin-top: 16px;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;                
                

                &:nth-child(1) {
                    margin-top: 0px;
                }

                .text {
                    width:100%;
                    p {
                        color: $dark;

                        &.t_14 {
                            color: $text;
                        }
                        &.date{
                            color:rgba($text, 0.7);
                            text-align: right;
                        }
                    }
                }
            }

            .no_notify{
                width:300px;
                display:flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                p{
                    color:$dark;
                    text-align: center;
                    max-width:200px;
                    &.t_14{
                        color:$text;
                    }
                }
img{
margin-top:32px;
}
            }
        }      
    }
}