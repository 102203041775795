@import "../../../scss/variables";

section.blog_cta {
    overflow: hidden;
    .content_wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0px;

        .info_col {
            padding: 0px 24px;

            h4 {
                margin-bottom: 8px;
            }

            &.mid {
                border-left: 2px solid $ternery;
                border-right: 2px solid $ternery;
            }

            a {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                padding: 4px 0px;
                margin-top: 8px;

                span.icon {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 8px;
                    color: $primary;

                    svg {
                        font-size: 22px;
                    }
                }

                span.arrow {
                    opacity: 0;

                    svg {
                        color: $primary;
                        transition: all 0.3s ease-in-out;
                    }
                }

                &:hover {
                    span.arrow {
                        opacity: 1;
                    }
                }
            }

            .email_box {
                
                margin-top: 16px;
                .input_line{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 4px;
                    @media only screen and (max-width:$mobile){
                        flex-direction: column;
                        gap:8px;
                        align-items: flex-start;
                    }    
                }

                input {
                    padding: 18px 20px;
                    border-radius: 56px;
                    border: 1px solid $primary;
                    height: 62px;
                    font-size: 16px;
                }

                @media only screen and (max-width:$tab) {
                        gap: 8px;
                        input {
                            height: 56px;
                            padding: 14px 16px;
                        }
                    }

                @media only screen and (max-width:$mobile) {

                    flex-direction: column;
                    align-items: flex-start;

                }
            }
        }

        @media only screen and (max-width:$laptop) {
            grid-template-columns: 1fr;
            gap: 32px;

            .info_col {
                padding-left: 0px;
                padding-right: 0px;

                &.mid {
                    border-style: none;
                }
            }
        }
    }
}