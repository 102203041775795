@import "../../../scss/variables";

.assignment {

    .offcanvas-body {

        .success_assignment {
            margin-top: 48px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            img {
                max-width: 100%;
            }

            h5 {
                text-align: center;
                margin-top: 16px;
            }
        }

        .file_upload_wrap {
            margin-top: 16px;
            border: 1px dashed $primary;
            border-radius: 16px;
            padding: 48px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;

            p {
                text-align: center;
            }

            svg {
                color: $text;
                font-size: 26px;
                margin-bottom: 8px;
            }

            input[type="file"] {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                opacity: 0;
            }
        }

        button {
            margin-top: 32px;
        }
    }
}