@import "../../scss/variables";

.error_field{
    margin-top:2px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top:-5px;
    p{
        text-transform: capitalize;
        font-size:14px;
        letter-spacing:0.5px;
        color:$error;
    }
}