@import "../../../scss/variables";

section.blog_landing {
    .content_wrap {
        display: flex;
        justify-content: center;

        .title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 16px;

            h1 {
                text-align: center;
            }

            p {
                text-align: center;
                max-width: 500px;
                width: 100%;
            }
        }
    }
}