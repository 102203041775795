@import "../../../scss/variables";

section.blog_list{
    overflow: hidden;
    .content_wrap{
        .blog_grid{
            margin-top:32px;
            display:grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap:24px;
            @media only screen and (max-width:$desktop){
                grid-template-columns: 1fr 1fr;
            }
            @media only screen and (max-width:$tab){
                grid-template-columns: 1fr;
            }
        }
    }
}