@import "../../../scss/variables";
@import "../../../scss/mixin";

section.blog_info {
    .content_wrap {

        .info_divider {
            display: grid;
            grid-template-columns: 0.5fr 1fr;
            gap: 48px;

            .inbox_wrap {
                align-self: flex-start;
                position: sticky;
                top: 92px;

                .inbox_card {
                    background-color: rgba($primary, 0.2);
                    padding: 32px;
                    border-radius: 0px 0px 32px 32px;
                    border-top: 5px solid $secondary;

                    .icon_box {
                        height: 56px;
                        width: 56px;
                        border-radius: 50%;
                        display: grid;
                        place-items: center;
                        background-color: $white;

                        svg {
                            color: $secondary;
                        }
                    }

                    h4 {
                        margin-top: 24px;
                    }

                    p {
                        margin-top: 8px;
                    }

                    input {
                        margin-top: 24px;                        
                        padding: 16px;
                        border-radius: 56px;
                        width: 100%;
                        border: 1px solid $primary;
                        background-color: transparent;
                    }

                    button {
                        width: 100%;
                        margin-top:8px;
                    }
                }

                .social_list {
                    display: flex;
                    justify-content: flex-start;
                    gap: 16px;
                    padding-top: 48px;
                    margin-top: 32px;
                    border-top: 1px solid $primary;

                    a {
                        svg {
                            font-size: 24px;
                            color: $primary;
                            transition: all 0.3s ease-in-out;
                        }

                        &:hover {
                            svg {
                                color: $secondary;
                            }
                        }
                    }
                }
            }

            .info_wrap {
                h4 {
                    margin-top: 16px;
                }

                p {
                    margin-top: 16px;
                }

                ul {
                    li {
                        @include font('Poppins-Regular', 16px, 24px, 400, $text);
                    }
                }

                .img_wrap {
                    margin-top: 32px;
                    margin-bottom: 32px;
                    display: grid;
                    place-items: center;

                    img {
                        max-width: 100%;
                        border-radius: 32px;
                    }
                }

                a {
                    color: $primary;
                    text-decoration: underline;
                }
            }

            @media only screen and (max-width:$tab) {
                grid-template-columns: 1fr;

                .inbox_wrap {
                    display: none;
                }
            }
        }
    }
}