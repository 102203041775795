@import "../../../scss/variables";

section.about_landing {
    background-color: $white;

    @media only screen and (max-width:$laptop) {
        display: none;
    }

    .content_wrap {
        position: relative;

        img.bg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 100%;
            max-height: 80vh;
            object-fit: contain;
            z-index: 0;
        }

        .grid_a {
            display: grid;
            grid-template-columns: 1fr 0.8fr 0.8fr;
            gap: 60px;
            position: relative;

            img.arrow_a {
                position: absolute;
                top: -68px;
                left: 50%;
                transform: translate(-50%, -32px);
            }

            .second {
                padding: 24px;

                img {
                    max-width: 300px;
                    width: 100%;
                }
            }

            .third {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
            }
        }

        .grid_b {
            display: grid;
            grid-template-columns: 1fr 1fr 0.8fr 1fr;
            gap: 24px;
            position: relative;

            @media only screen and (max-width:$desktop) {
                grid-template-columns: 1fr 1fr 1fr;
            }

            img.arrow_b {
                position: absolute;
                top: 0px;
                left: 50%;
                transform: translateX(-50%);
            }

            .a {
                padding-top: 150px;
                padding-left: 80px;
            }

            .b {
                padding-top: 260px;

                img {
                    width: 216px;
                }
            }

            .c {
                padding-top: 130px;
                padding-right: 48px;
                position: relative;

                img {
                    position: absolute;
                    left: -48px;
                    top: 250px;
                    &.arrow_c{
                        transform:translateY(48px);
                    }
                }
            }

            .d {
                padding-top: 24px;

                img {
                    width: 216px;
                }

                @media only screen and (max-width:$desktop) {
                    display: none;
                }
            }
        }
    }
}

section.about_landing_tab {

    display: none;

    @media only screen and (max-width:$laptop) {
        display: block;
    }

    .content_wrap {
        position: relative;

        img.bg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 100%;
            max-height: 80vh;
            object-fit: contain;
            z-index: 0;
        }

        .title {
            position: relative;
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h1 {
                text-align: center;
            }

            p {
                margin-top: 16px;
                text-align: center;
                max-width: 400px;
                width: 100%;
            }
        }

        .img_wrap {
            margin-top: 32px;
            position: relative;
            z-index: 2;

            img {
                width: 100%;
            }
        }
    }
}