@import "../../../scss/variables";

section.lesson_info_list {
    padding-bottom: 48px;

    .content_wrap {
        padding-top: 24px;

        button.back {
            background-color: transparent;
            border-style: none;
            margin-bottom: 8px;

            svg {
                font-size: 24px;

            }
            &:hover{
                svg{
                    color:$secondary;
                }
            }
        }

        p.title {
            color: $primary;
            margin-bottom: 8px;
        }

        .btns_wrapers {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 24px;
            border-bottom: 1px solid rgba($text, 0.4);
            margin-bottom: 24px;

            button {
                border-style: none;
                width: 100%;
                background-color: transparent;
                border-bottom: 2px solid $white;
                padding: 16px;
                color: $text;
                width: 150px;

                @media only screen and (max-width:$mobile) {
                    width: 50%;
                }

                &.active {
                    color: $secondary;
                    border-bottom: 2px solid $secondary;
                }
            }
        }

        .cirtificate {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            button {
                margin-top: 32px;
                width: 250px;
            }

            img {
                max-width: 600px;
                width: 100%;
            }
        }

        .lessons_grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 32px;

            @media only screen and (max-width:$laptop) {
                grid-template-columns: 1fr;
                gap: 24px;
            }

            @media only screen and (max-width:$tab) {
                gap: 16px;
            }

            @media only screen and (max-width:$mobile) {
                gap: 8px;
            }

            .lesson_card {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 16px;
                border-radius: 8px;
                padding: 16px;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                cursor: pointer;

                // &.locked {
                //     pointer-events: none;
                // }

                @media only screen and (max-width:$mobile) {
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    gap: 0px;
                }

                h5 {
                    color: $text;

                    svg {
                        font-size: 22px;
                    }
                }

                span {
                    svg {
                        color: $primary;
                        font-size: 24px;
                    }
                }
            }
        }
    }
}