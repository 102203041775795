@import "../../scss/variables";

.payment_fail {
    .content_wrap {
        height: 50vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;

        h1 {
            text-align: center;
            color: $error;


        }

        p {
            text-align: center;
            color: $error;
        }

        button {
            margin-top: 16px;
        }
    }
}