@import "../../../scss/variables";

section.course_counter {
    .content_wrap {
        .counter_grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 24px;
            max-width: 1000px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;

            .counter_card {
                padding: 32px 24px;
                border-radius: 24px;

                h1 {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    span {
                        font-size: 84px;
                        font-weight: 400;
                    }
                }

                p {
                    margin-top:0px;
                }

                &.a {
                    background-color: $ternery;

                    h1 {
                        color: $secondary;
                    }

                    p {
                        color: $secondary;

                        &.m_t {
                            margin-top: 100px;
                        }
                    }
                }

                &.b {
                    background-color: $darkYelowLight;

                    h1 {
                        color: $darkYellow;
                    }

                    p {
                        color: $darkYellow;

                        &.m_t {
                            margin-top: 100px;
                        }
                    }
                }

                &.c {
                    background-color: $greenLight;

                    h1 {
                        color: $green;
                    }

                    p {
                        color: $green;

                        &.m_t {
                            margin-top: 100px;
                        }
                    }
                }

                &.d {
                    background-color: $yellowLight;

                    h1 {
                        color: $yellow;
                    }

                    p {
                        color: $yellow;

                        &.m_t {
                            margin-top: 100px;
                        }
                    }
                }
            }

            @media only screen and (max-width:$laptop) {
                grid-template-columns: 1fr 1fr;

                .counter_card {
                    h1 {
                        span {
                            font-size: 60px;
                        }
                    }
                }
            }

            @media only screen and (max-width:$mobile) {
                grid-template-columns: 1fr;

                .counter_card {
                    h1 {
                        span {
                            font-size: 48px;
                        }
                    }
                }
            }
        }
    }
}