@import "../../../../scss/variables";

.blog_card {
    display: flex;
    flex-direction: column;
    padding-bottom:16px;
    position:relative;
    cursor:pointer;
   

    .img_wrap {
        overflow: hidden;

        img {
            object-fit: cover;
            min-height: 250px;
            width: 100%;
            border-radius: 24px;          
        }
    }

    p.text{
        margin: 16px 0px 8px;
        display: -webkit-box;
        -webkit-line-clamp:2;
        -webkit-box-orient: vertical;  
        overflow: hidden;
    }
    h4{
        display: -webkit-box;
        -webkit-line-clamp:2;
        -webkit-box-orient: vertical;  
        overflow: hidden;
    }
}