@import "../../../scss/variables";

section.course_list_sec {
    .content_wrap {
        .course_tab_wrap {
            display:flex;
            justify-content: center;
            align-items: center;
            .tab_wraper_box {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 8px;
                border: 1px solid $ternery;
                padding: 4px;
                border-radius: 56px;
                margin-bottom: 8px;
                border: 1px solid $primary;
                width:400px;
                @media only screen and (max-width:$tab){
                   width:350px;
                   gap:0px;
                }
                @media only screen and (max-width:$mobile){
                    width:100%;                  
                 }
                button {
                    
                    padding: 18px 40px;
                    border-radius: 56px;
                    background-color: transparent;
                    border-style: none;
                    color: $text;
                    font-weight: 400;
                    transition: all 0.3s ease-in-out;
                    @media only screen and (max-width:$tab){
                        padding: 14px 16px;
                    }

                    &.active {
                        background-color: $primary;
                        color: $white;
                    }
                }
            }
        }


        .course_list_grid{
            margin-top:48px;
            display:grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap:24px;
            @media only screen and (max-width:$desktop){
                grid-template-columns: 1fr 1fr;
            }
            @media only screen and (max-width:$tab){
                grid-template-columns: 1fr;
            }
        }
    }
}