@import "../../scss/variables";

section.lms_faq{
    .content_wrap{
        .faq_search_wrap{
            margin-top:16px;
            border-radius: 8px;
            background-color:rgba($text,0.05);
            position:relative;
            input{
                padding:12px 48px;
                border-style: none;
                background-color: transparent;
                width:100%;
            }
            span{
                position:absolute;
                top:50%;
                left:12px;
                transform:translateY(-50%);
                svg{
                    color:$text;
                    font-size:20px;
                }
            }
         
        }
        
        .accordion{
            margin-top:32px;
            display:grid;
            grid-template-columns: 1fr;
            gap:8px;
          
            .accordion-item{
                border-radius: 8px;
                overflow: hidden;
                align-self: flex-start;

                .accordion-header{
                    button{
                        background-color: $primary;
                        position:relative;

                        svg{
                            color:$white;
                            font-size:20px;
                            position:absolute;
                            top:50%;
                            right:16px;
                            transform-origin: center;
                            transform:translateY(-50%) rotate(0deg);
                            transition:all 0.3s ease-in-out;
                        }
                        p{
                            color:$white;
                        }
                        &:focus{
                            box-shadow:none;
                            outline:none;
                        }
                        &::after{
                            display:none;
                        }
                        &.collapsed{
                            svg{
                               
                                transform:translateY(-50%) rotate(180deg);
                            }                            
                        }
                    }
                }
                .accordion-collapse{
                    .accordion-body{
                        background-color: rgba($primary, 0.1);

                    }
                }

            }
        }
    }
}