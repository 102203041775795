@import "../../../scss/variables";

section.course_info{
    background-color:$white;
    border-radius:48px 48px 0px 0px;
    margin-top:24px;
    .content_wrap{
        .name_line{
            display:flex;
            justify-content: space-between;
            align-items: center;
            gap:16px;
            @media only screen and (max-width:$mobile){
                flex-direction: column;
                gap:8px;
                align-items: flex-start;
            }
            .rating{
                display:flex;
                justify-content:flex-end;
                align-items: center;
                gap:8px;
                svg{
                    font-size:20px;
                    color:$yellow;
                }

            }
        }
        .info_line{
            margin-top:16px;
            display:flex;
            justify-content: flex-start;
            align-items: center;
            row-gap:8px;
            column-gap:24px;;
            flex-wrap: wrap;
            .info{
                display:flex;
                justify-content: flex-start;
                align-items: center;
                gap:8px;
                h5{
                    color:$text;
                    margin:0px;
                    font-weight:400;
                }
                svg{
                    color:$text;
                    font-size:24px;
                }
                @media only screen and (max-width:$mobile){
                    h5{
                        font-size:14px;
                        line-height:18px;
                    }
                    svg{
                        font-size:16px;
                    }
                }
            }
           

        }
        .btns_wrapers{
            display:flex;
            justify-content: flex-start;
            align-items: center;
            margin-top:24px;
            border-bottom:1px solid rgba($text,0.4);
            margin-bottom:24px;
            button{
                border-style: none;
                width:100%;
                background-color: transparent;
                border-bottom:2px solid $white;
                padding:16px;
                color:$text;
                width:150px;
                @media only screen and (max-width:$mobile){
                    width:50%;
                }
                &.active{
                    color:$secondary;
                    border-bottom:2px solid $secondary;
                }
                &:disabled{
                    opacity:0.5;
                }
            }
        }
    }
}